import { Typography } from "@material-ui/core";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import MoneyIcon from "@material-ui/icons/Money";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import React from "react";
import CurrencyFormat from "react-currency-format";
import GlobalStyles from "../../styles/GlobalStyles";
import PrimaryCard from "./PrimaryCard";
import useStyles from "./styles";

function SecondaryCard(props) {
  const classes = useStyles();
  const dashboard = props.dashboard;
  const globalClasses = GlobalStyles();
  

  return (
    <div className="secondary__cards">
      
      <PrimaryCard
        cardTitle="BANK AMOUNT"
        colorAvatar={classes.orangeAvatar}
        cardAmount={
           <CurrencyFormat
            value={dashboard.data?.bank_balance}
            className={globalClasses.ibs_font_weight_md}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"R "}
          />
        }
        secondCardTitle = "PETTY CASH"
        secondAmount ={
          <CurrencyFormat
          value={dashboard.data?.petty_cash}
          displayType={"text"}
          className={globalClasses.ibs_font_weight_md}
          thousandSeparator={true}
          prefix={"R "}
          />
        }
        cardIcon={<AccountBalanceIcon />}
        dashboardInfo={dashboard}
      />

      {/* Total Income budget card */}
      <PrimaryCard
        cardTitle="TOTAL INCOME"
        colorAvatar={classes.greenAvatar}
        cardAmount={
          <CurrencyFormat
            value={dashboard.data?.total_income}
            displayType={"text"}
            thousandSeparator={true}
            className={globalClasses.ibs_font_weight_md}
            prefix={"R "}
          />
        }
        cardIcon={<SaveAltIcon />}
        period="since last month"
        dashboardInfo={dashboard}
        cardPercentage={
          <Typography
            color="textSecondary"
            gutterBottom
            variant="h6"
            className={
              dashboard.data?.income_percentage_change > 0
                ? `${classes.cardContentPercentageGain} ${globalClasses.ibs_font_size_sm}`
                : `${classes.cardContentPercentageLoss} ${globalClasses.ibs_font_size_sm}`
            }
            style={{ margin: 0 }}
          >
            {Math.abs(dashboard.data?.income_percentage_change)} %
          </Typography>
        }
        arrow={
          dashboard.data?.income_percentage_change > 0 ? (
            <ArrowUpwardIcon className={classes.cardContentPercentageGain} />
          ) : (
            <ArrowDownwardIcon className={classes.cardContentPercentageLoss} />
          )
        }
      />
      {/* Total expense budget card */}
      <PrimaryCard
        cardTitle="TOTAL EXPENSES"
        colorAvatar={classes.redAvatar}
        cardAmount={
          <CurrencyFormat
            value={dashboard.data?.total_expenses}
            displayType={"text"}
            className={globalClasses.ibs_font_weight_md}
            thousandSeparator={true}
            prefix={"R "}
          />
        }
        dashboardInfo={dashboard}
        cardPercentage={
          <Typography
            color="textSecondary"
            gutterBottom
            variant="h6"
            className={
              dashboard.data?.expenses_percentage_change > 0
                ? `${classes.cardContentPercentageGain} ${globalClasses.ibs_font_size_sm}`
                : `${classes.cardContentPercentageLoss} ${globalClasses.ibs_font_size_sm}`
            }
            style={{ margin: 0 }}
          >
            {Math.abs(dashboard.data?.expenses_percentage_change)}%
          </Typography>
        }
        cardIcon={<MoneyIcon />}
        period="since last month"
        arrow={
          dashboard.data?.expenses_percentage_change > 0 ? (
            <ArrowUpwardIcon className={classes.cardContentPercentageGain} />
          ) : (
            <ArrowDownwardIcon className={classes.cardContentPercentageLoss} />
          )
        }
      />
      {/* Total Profit budget card */}
      <PrimaryCard
        cardTitle="TOTAL PROFIT"
        colorAvatar={classes.blueAvatar}
        cardAmount={
          <CurrencyFormat
            value={dashboard.data?.profit}
            displayType={"text"}
            className={`${globalClasses.ibs_font_weight_md} `}
            thousandSeparator={true}
            prefix={"R "}
          />
        }
        dashboardInfo={dashboard}
        cardPercentage={
          <Typography
            color="textSecondary"
            gutterBottom
            variant="h6"
            className={
              dashboard.data?.profit_percentage_change > 0
                ? `${classes.cardContentPercentageGain} ${globalClasses.ibs_font_size_sm}`
                : `${classes.cardContentPercentageLoss} ${globalClasses.ibs_font_size_sm}`
            }
            style={{ margin: 0 }}
          >
            {Math.abs(dashboard.data?.profit_percentage_change)}%
          </Typography>
        }
        cardIcon={<AttachMoneyIcon />}
        period="since last month"
        arrow={
          dashboard.data?.profit_percentage_change > 0 ? (
            <ArrowUpwardIcon className={classes.cardContentPercentageGain} />
          ) : (
            <ArrowDownwardIcon className={classes.cardContentPercentageLoss} />
          )
        }
      />
    </div>
  );
}

export default SecondaryCard;
