import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme =>({
  appRoot: {
    display: "flex",
  },
  navigationLogo: {
    width: '50%',
  },
  navigationDrawer:{
    width: 240,
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    position: 'relative',
    height: '155vh'
  },
  navigationToolBar:{
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: theme.spacing(1),
    ...theme.mixins.toolbar
  },
  navigationList:{
    display: 'flex',
    flexDirection: "column",
    alignItems: "center"
  },
  navigationDrawerCollapse : {
    width: theme.spacing(8)
  },
  menuItemIcon:{
    color: "#AF71EC",
    width: "100%"
  },
  navigationLogoContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "10px",
    paddingBottom: theme.spacing(6),
  },
  menuItem:{
    width: "90%",
    marginBottom: 8
  },
}))