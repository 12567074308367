import React, {lazy, Suspense} from 'react'
import DashboardIcon from '@mui/icons-material/Dashboard';
import PaidIcon from '@mui/icons-material/Paid';
import { useDispatch } from "react-redux";
import {
    useParams
  } from "react-router-dom";
import { getDynamicCompanyDatabase } from '../redux/companyDbSlice';


const Home = lazy(() => import("../Home/Home"))
const Transactions = lazy(() => import("../Accounting/Transactions/Transactions"))

const BASE_COLOR = "#AF71EC";


const Routes = () => {
    const dispatch = useDispatch();
    let { database } = useParams();
    dispatch(
        getDynamicCompanyDatabase({
            companyDatabase: database
        })
    )
   
    const routes = [
        {
            label: 'Dashboard',
            path: `/dashboard/home/${database}`,
            icon: <DashboardIcon />,
            activeIcon: <DashboardIcon style={{color: BASE_COLOR}}/>,
            component: <Suspense fallback={<div>Loading....</div>}><Home /></Suspense>
        },
    
        {
            label: 'Transactions',
            path: `/dashboard/transactions/${database}`,
            icon: <PaidIcon />,
            activeIcon: <PaidIcon style={{color: BASE_COLOR}}/>,
            component: <Suspense fallback={<div>Loading..</div>}><Transactions /></Suspense>
        }
    ]

    return routes;
}

export default Routes;