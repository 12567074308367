import {
  Avatar,
  Card,
  CardContent,
  Chip,
  IconButton,
  TablePagination,
  Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useState } from "react";
import InfoMessage from "../../utilis/InfoMessage/InfoMessage";
// import "./layout.css";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
} from "@material-ui/core";
import useStyles from "./styles";

import { Box, TextField } from "@mui/material";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import GlobalStyles from "../../styles/GlobalStyles";
import {
  getTransactionHistory,
  reverseAssetCash,
  reverseCashExpense,
  reverseCashIncome,
  reverseExpensePayables,
  reverseReceivableIncome,
} from "../../utilis/api";
import {
  DELETE_ASSET_CASH_API_ROUTE,
  DELETE_EXPENSE_CASH_API_ROUTE,
  DELETE_EXPENSE_PAYABLE_API_ROUTE,
  DELETE_INCOME_API_ROUTE,
  DELETE_INCOME_RECEIVABLE_API_ROUTE,
  HISTORY_API_ROUTE,
} from "../../utilis/configurations";

import * as moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

function History(props) {
  const classes = useStyles();
  const [message, setMessage] = useState("");
  const [history, setHistory] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [historyInfo, setHistoryInfo] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState(false);
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const globalClasses = GlobalStyles();
  const { database } = useParams();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const getHistory = () => {
    getTransactionHistory(
      HISTORY_API_ROUTE(
        moment(startDate).format("YYYY-MM-DD"),
        moment(endDate).format("YYYY-MM-DD"),
        database
      )
    )
      .then((data) => data.json())
      .then((history) => {
        setHistory(history);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleOpenConfirmation = (transaction) => {
    setSelectedTransaction(transaction);
    setConfirmationOpen(true);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  useEffect(() => {
    getHistory();
  }, [startDate, endDate, deleteMessage]);

  const reverseResponseData = (response) => {
    if (response && response.data && response.data.message) {
      toast.success(response.data.message, { position: "top-right" });
      setDeleteMessage(true);

      setTimeout(() => {
        setDeleteMessage(false);
      }, 1000);
    }
  };

  const handleCloseConfirmation = () => {
    setConfirmationOpen(false);
  };

  const handleConfirmDeletion = async () => {
    handleCloseConfirmation();

    if (!selectedTransaction) {
      console.error("No transaction data selected for deletion.");
      return;
    }

    try {
      const deleteData = {
        transaction_id: parseInt(selectedTransaction.transaction_id),
        category_1: selectedTransaction.category_1,
        category_2: selectedTransaction.category_2,
        amount: parseFloat(selectedTransaction.amount),
        outstanding_capital: parseFloat(
          selectedTransaction.outstanding_capital
        ),
      };

      let response;

      if (
        deleteData.category_1 === "Income" &&
        (deleteData.category_2 === "Bank" ||
          deleteData.category_2 === "Petty Cash")
      ) {
        response = await reverseCashIncome(
          deleteData.transaction_id,
          deleteData.category_1,
          deleteData.category_2,
          deleteData.amount,
          DELETE_INCOME_API_ROUTE(database)
        );
      } else if (
        deleteData.category_1 === "Expense" &&
        (deleteData.category_2 === "Bank" ||
          deleteData.category_2 === "Petty Cash")
      ) {
        response = await reverseCashExpense(
          deleteData.transaction_id,
          deleteData.category_1,
          deleteData.category_2,
          deleteData.amount,
          DELETE_EXPENSE_CASH_API_ROUTE(database)
        );
      } else if (
        deleteData.category_1 === "Asset" &&
        deleteData.category_2 === "Bank"
      ) {
        response = await reverseAssetCash(
          deleteData.transaction_id,
          deleteData.category_1,
          deleteData.category_2,
          deleteData.amount,
          DELETE_ASSET_CASH_API_ROUTE(database)
        );
      } else if (
        deleteData.category_1 === "Expense" &&
        deleteData.category_2 === "Payable"
      ) {
        response = await reverseExpensePayables(
          deleteData.transaction_id,
          deleteData.category_1,
          deleteData.category_2,
          deleteData.amount,
          DELETE_EXPENSE_PAYABLE_API_ROUTE(database)
        );
      } else if (
        deleteData.category_1 === "Income" &&
        deleteData.category_2 === "Receivable"
      ) {
        response = await reverseReceivableIncome(
          deleteData.transaction_id,
          deleteData.category_1,
          deleteData.category_2,
          deleteData.amount,
          DELETE_INCOME_RECEIVABLE_API_ROUTE(database)
        );
      }

      ////reverseResponseData(response);

      const updatedHistory = history.map((item) =>
        item.transaction_id === selectedTransaction.transaction_id
          ? { ...item, status: "Inactive" }
          : item
      );

      setHistory(updatedHistory);
    } catch (error) {
      console.error("Error reversing transaction:", error);
    } finally {
      setSelectedTransaction(null);
    }
  };

  const columnHeaderTable = [
    { heads: "Date", alignment: "" },
    { heads: "Description", alignment: "left" },
    { heads: "Category 1", alignment: "right" },
    { heads: "Category 2", alignment: "right" },
    { heads: "Category 3", alignment: "right" },
    { heads: "Amount", alignment: "right" },
    { heads: "Active/Inactive", alignment: "right" },
    { heads: "Delete", alignment: "right" },
  ];

  return (
    <>
      <Card className={`${classes.root} ${globalClasses.ibs_p_20}`}>
        <CardContent>
          <Typography
            style={{ color: "#5c5b5f" }}
            className={`${globalClasses.ibs_font_weight_md} ${globalClasses.ibs_mb_20} ${globalClasses.ibs_ml_30}`}
          >
            Transaction History
          </Typography>
          <Box display={"flex"} className={globalClasses.ibs_mb_20}>
            <Box mr={2}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Start Date"
                  inputFormat="YYYY-MM-DD"
                  className={globalClasses.ibs_mr_20}
                  value={startDate}
                  onChange={(newValue) => {
                    setStartDate(newValue.$d);
                  }}
                  renderInput={(params) => (
                    <TextField variant="filled" color="secondary" {...params} />
                  )}
                />
              </LocalizationProvider>
            </Box>

            <Box>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="End Date"
                  value={endDate}
                  inputFormat="YYYY-MM-DD"
                  onChange={(newValue) => {
                    setEndDate(newValue.$d);
                  }}
                  renderInput={(params) => (
                    <TextField variant="filled" color="secondary" {...params} />
                  )}
                />
              </LocalizationProvider>
            </Box>
          </Box>
          <Paper>
            <TableContainer>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {columnHeaderTable.map((head) => (
                      <TableCell
                        className={`${globalClasses.ibs_font_weight_md} ${globalClasses.ibs_text_color}`}
                        align={head.alignment}
                      >
                        {head.heads}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {history
                    ? history
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, i) => (
                          <TableRow key={row.name}>
                            <TableCell
                              className={globalClasses.ibs_text_color}
                              component="th"
                              scope="row"
                            >
                              {row.datetime.slice(0, 10)}
                            </TableCell>
                            <TableCell
                              className={globalClasses.ibs_text_color}
                              align="left"
                            >
                              {row.description}
                            </TableCell>
                            <TableCell
                              className={globalClasses.ibs_text_color}
                              align="right"
                            >
                              {row.category_1}
                            </TableCell>
                            <TableCell
                              className={globalClasses.ibs_text_color}
                              key={i}
                              data-id={row.id}
                              align="right"
                            >
                              {row.category_2}
                            </TableCell>
                            <TableCell
                              className={globalClasses.ibs_text_color}
                              align="right"
                            >
                              {row.category_3}
                            </TableCell>
                            <TableCell
                              className={globalClasses.ibs_text_color}
                              align="right"
                            >
                              {row.amount}
                            </TableCell>
                            <TableCell
                              className={globalClasses.ibs_text_color}
                              align="right"
                            >
                              {row.status == "Active" ? (
                                <Chip
                                  color="primary"
                                  avatar={<Avatar>A</Avatar>}
                                  label="Active"
                                />
                              ) : (
                                <Chip
                                  style={{ backgroundColor: "#e12534" }}
                                  avatar={
                                    <Avatar
                                      style={{ backgroundColor: "#7a0b14" }}
                                    >
                                      D
                                    </Avatar>
                                  }
                                  label="Inactive"
                                />
                              )}
                            </TableCell>
                            <TableCell align="right">
                              {row.status === "Active" ? (
                                <IconButton
                                  data-div_id={row.transaction_id}
                                  data-div_name={row.category_1}
                                  data-div_name2={row.category_2}
                                  data-div_status={row.status}
                                  data-div_amount={row.amount}
                                  data-div_outstanding={row.outstanding_capital}
                                  onClick={() => handleOpenConfirmation(row)}
                                >
                                  <DeleteIcon color="primary" />
                                </IconButton>
                              ) : (
                                <IconButton
                                  data-div_id={row.transaction_id}
                                  data-div_name={row.category_1}
                                  data-div_name2={row.category_2}
                                  data-div_status={row.status}
                                  data-div_amount={row.amount}
                                  disabled={true}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              )}
                            </TableCell>
                          </TableRow>
                        ))
                        .reverse()
                    : ""}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 8]}
              component="div"
              count={history ? history.length : ""}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
          {historyInfo === true ? <InfoMessage message={message} /> : ""}
        </CardContent>
      </Card>

      <Dialog open={isConfirmationOpen} onClose={handleCloseConfirmation}>
        <DialogContent>
          <p>Are you sure you want to delete this transaction?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmation}>Cancel</Button>
          <Button onClick={handleConfirmDeletion} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default History;
