import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles(theme => ({
    root: {
      "& .MuiTextField-root": {
        margin: theme.spacing(1),
        width: "80%",
        minHeight: "530px",
      },
    },
  
    field: {
      width: "20%",
    },
  
    alert: {
      width: "30%",
    },
  
    submitButton: {
      float: 'right',
      marginRight: 53
    },
  
    formControl: {
      margin: theme.spacing(0.02),
      minWidth: 130,
    },
  
    selectEmpty: {
      margin: theme.spacing(1.4),
    },
    inputLabel:{
      marginTop: 3,
    },
    AdditionalText:{
      marginBottom: 6,
      padding: 26
    },
    AdditionalFields:{
      marginRight: 17,
      marginBottom: 20,
    },
    AdditionalLayout:{
      display: 'flex',
    },
    loader:{
      display: "grid",
      placeItems: "center"
    }


    
}));

export default useStyles;