import React from "react";
import "./SecondaryCards.css";
import PrimaryCard from "../AccountingPrimaryCard/PrimaryCard";
import { Link } from "react-router-dom";
import { Badge, makeStyles } from "@material-ui/core";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import {
  useParams
} from "react-router-dom";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  activeCard: {
    borderLeftColor: "hsl(264, 39%, 51%)",
    borderLeftStyle: "solid",
    borderLeftWidth: "4px",
  },
});

function SecondaryCards() {
  const classes = useStyles();
  let {database} = useParams();

  return (
    <div className="transaction__button">
      <div className="operation__cards">
        <Link
          to={`/dashboard/transactions/${database}`}
          style={{ textDecoration: "none" }}
        >
          <PrimaryCard
            cardName="Transact"
            cardDescription="Make daily transactions records and record incomes and expenses"
            cardPic={`https://app.integrated-business-solutions.com/static/media/Transaction.51607b23.svg`}

          />
        </Link>
      </div>
      <div className="operation__cards">
        <Link
          to={`/dashboard/history/${database}`}
          style={{ textDecoration: "none" }}
        >
          <PrimaryCard
            cardName="History"
            cardDescription="Check history transactions and track daily incomes and expenses"
            className="entries"
            cardPic= {"https://app.integrated-business-solutions.com/static/media/History.2f5d4eb8.svg"}
          />
        </Link>
      </div>
      <div className="operation__cards">
        <Link to={`/dashboard/statements/${database}`}>
          <PrimaryCard
            cardName="Statements"
            cardDescription="View Monthly Statements and check the total profit made for the month"
            className="entries"
            cardPic={"https://app.integrated-business-solutions.com/static/media/Statements.65e8c8fc.svg"}
          />
        </Link>
       
        
      </div>
      <div className="operation__cards">
        
          <PrimaryCard
            cardName="Comprehensive"
            cardDescription="view total assets and liability. Dispose and check liability score"
            className="entries"
            cardPic={"https://app.integrated-business-solutions.com/static/media/Property.a9e04b1f.svg"}
            color="primary" 
            cardAvailability="Service Not Available yet"
            style={{ width:"100%" }}
            availability = "Available Later this year"
            promoIcon = {<AccessTimeIcon />}
          />
      </div>
    </div>
  );
}

export default SecondaryCards;
