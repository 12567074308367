export const CategoryOne = [
    "Income",
    "Asset",
   "Expense",
 "Liability"
]
export const CategoryTwoIncome = [
    "Receivable",
    "Bank",
    "Petty Cash"
]

export const CategoryTwoExpense = [
    "Payable",
    "Bank",
    "Petty Cash"
]

export const CategoryTwoAsset = [
    "Bank",
    "Credit",
    "Hybrid"
]
export const CategoryTwoLiability = [
    "Receive",
    "Pay"
]