import { blue, green, purple, red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';



const useStyles = makeStyles((theme) => ({
    BudgetCardRoot:{
        width:  '100%',
        marginRight: '20px',
        marginBottom: '28px',
    },
    orangeAvatar: {
        color: theme.palette.getContrastText(purple[600]),
        backgroundColor: purple[700],
        height: '56px',
        width: '56px',
        float: 'right',  
    },
    blueAvatar: {
        color: theme.palette.getContrastText(blue[500]),
        backgroundColor: blue[800],
        height: '56px',
        width: '56px',
        float: 'right',
        
    },

    skeletonStyle:{
        marginBottom: "20px",
    },
    greenAvatar:{
        color: theme.palette.getContrastText(green[800]),
        backgroundColor: green[600],
        height: '56px',
        width: '56px',
        float: 'right',
       
    },
    redAvatar:{
        color: theme.palette.getContrastText(red[500]),
        backgroundColor: red[600],
        height: '56px',
        width: '56px',
        float: 'right',
    },

    cardContentPercentageLoss: {
        marginRight: '4px',
        marginBottom: '5px',
        color : 'rgb(183, 28, 28)',
    },

    cardContentPercentageGain: {
        marginRight: '4px',
        marginBottom: '5px',
        color : 'rgb(27, 94, 32)',
    },

    cardTitleFonts:{
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontWeight: 500,
        fontSize: '13px',
        lineHeight: '1.167',
        letterSpacing: '-0.06px',
        marginBottom: '10px',
    },
    cardContentFonts:{
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontWeight: 500,
        fontSize: '22px',
    },
    
}));

export default useStyles;