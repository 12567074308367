import {
  Avatar,
  Card,
  CardContent,
  Divider,
  Typography
} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import Skeleton from "@material-ui/lab/Skeleton";
import React from "react";
import CurrencyFormat from "react-currency-format";
import GlobalStyles from "../../styles/GlobalStyles";
import "./layout.css";
import useStyles from "./styles";

function PayReceiveCardTotal(props) {
  const classes = useStyles();
  const globalClasses = GlobalStyles();
  const dashboardInfo = props.dashboard;
  const totalPayables = props.dashboard.data?.total_payables;
  const totalReceivables = props.dashboard.data?.total_receivebles;

  return (
    <Card className={classes.totalCardRoot}>
      <Typography variant="body2" gutterBottom className={`${globalClasses.ibs_font_size_md} ${globalClasses.ibs_p_20}`}>
        Receivables & Payables
      </Typography>
      <Divider />

      <CardContent className={classes.innerCardRoot}>
        {dashboardInfo.length !== 0 ? (
          <React.Fragment>
            <Typography variant="body2" className={classes.cardTitleFonts}>
              TOTAL RECEIVABLES
            </Typography>
            <Chip
              color="primary"
              avatar={<Avatar>R</Avatar>}
              label="Receivables"
            />
            <Typography
              color="primary"
              variant="body2"
              className={`${classes.cardAmountFonts} ${globalClasses.ibs_font_weight_md}`}
            >
              R{" "}
              <CurrencyFormat
                value={totalReceivables}
                displayType={"text"}
                thousandSeparator={true}
              />
            </Typography>

            <Typography className={classes.cardTitleFonts}>
              TOTAL PAYABLES
            </Typography>
            <Chip
              style={{backgroundColor: "#e12534"}}
              avatar={<Avatar style={{backgroundColor: "#7a0b14"}}>P</Avatar>}
              label="Payables"
            />
            <Typography
              color="error"
              className={`${classes.cardAmountFonts} ${globalClasses.ibs_font_weight_md}`}
            >
              R{" "}
              <CurrencyFormat
                value={totalPayables}
                displayType={"text"}
                thousandSeparator={true}
              />
            </Typography>
          </React.Fragment>
        ) : (
          <div className="skeleton__layout">
            <Skeleton animation="wave" width={"40%"} />
            <Skeleton animation="wave" width={"60%"} height={41} />
            <Skeleton animation="wave" width={"80%"} height={65} />
            <Skeleton animation="wave" width={"40%"} />
            <Skeleton animation="wave" width={"60%"} height={41} />
            <Skeleton animation="wave" width={"80%"} height={55} />
          </div>
        )}
      </CardContent>
    </Card>
  );
}

export default PayReceiveCardTotal;
