export const errorHandlingObj = {
    NETWORK_ERR: "Network Error",
    UNAUTH_USER: "Request failed with status code 401"
};

export const messageToastResponseObj = {
    NETWORK_ERR_MESSAGE: "Please check internet connectivity",
    UNAUTH_USER_MESSAGE: "Invalid User Details"
};

export const severityObj = {
    SUCCESS: "success",
    ERROR: "error",
    WARNING: "warning"
}