import { createSlice } from "@reduxjs/toolkit";

export const companyDBSlice = createSlice({
    name: "company_db",
    initialState: {
        DB_NAME: null,
    },
    reducers: {
        getDynamicCompanyDatabase: (state, action) => {
            state.DB_NAME = action.payload
        }
    }
});

export const {getDynamicCompanyDatabase} = companyDBSlice.actions;

export const selectCompanyDB = (state) => state.DB_NAME.DB_NAME;

export default companyDBSlice.reducer;