import React from "react";
import { Avatar, Card, CardContent, Typography } from "@material-ui/core";
import "./layout.css";
import useStyles from "./styles";
import Skeleton from "@material-ui/lab/Skeleton";

function PrimaryCard({
  cardTitle,
  cardAmount,
  colorAvatar,
  cardIcon,
  arrow,
  cardPercentage,
  secondAmount,
  secondCardTitle,
  period,
  dashboardInfo,
}) {
  const classes = useStyles();

  return (
    <Card className={classes.BudgetCardRoot} variant="outlined">
      <CardContent>
        {dashboardInfo.length !== 0 ? (
          <Typography
            color="textSecondary"
            gutterBottom
            variant="h6"
            className={classes.cardTitleFonts}
          >
            {cardTitle}
          </Typography>
        ) : (
          <Skeleton
            variant="square"
            animation="wave"
            width={"30%"}
            height={10}
            className={classes.skeletonStyle}
          />
        )}
        {/* Card loader animation card title*/}

        <div className="card__content">
          {dashboardInfo.length !== 0 ? (
            <Typography variant="h6" className={classes.cardContentFonts}>
              {cardAmount}
            </Typography>
          ) : (
            <Skeleton
              variant="square"
              animation="wave"
              width={"35%"}
              height={20}
            />
          )}
          {/* Card loader animation card content*/}

          {dashboardInfo.length !== 0 ? (
            <Avatar className={colorAvatar} variant="square">
              {cardIcon}
            </Avatar>
          ) : (
            <Skeleton
              variant="square"
              animation="wave"
              width={"16%"}
              height={56}
            />
          )}
          {/* Card loader animation card icon*/}
        </div>
        <div className="card__secondInfo">
          {dashboardInfo.length !== 0 ? (
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
              className={classes.cardTitleFonts}
            >
              {secondCardTitle}
            </Typography>
          ) : (
            <Skeleton
              variant="square"
              animation="wave"
              width={"16%"}
              height={26}
            />
          )}
          <Typography variant="h6" className={classes.cardContentFonts}>
            {secondAmount}
          </Typography>
        </div>
        <div className="card__stats">
          {dashboardInfo.length !== 0 ? (
            <React.Fragment>
              {arrow}
              <Typography
                color="textSecondary"
                gutterBottom
                variant="h6"
                className={classes.cardContentPercentageLoss}
                style={{ marginRight: "4px" }}
              >
                {cardPercentage}
              </Typography>

              <Typography
                color="textPrimary"
                variant="caption"
                gutterBottom
                style={{ color: "#bdc0c5" }}
              >
                {period}
              </Typography>
            </React.Fragment>
          ) : (
            <Skeleton
              variant="square"
              animation="wave"
              width={"16%"}
              height={18}
            />
          )}
        </div>
      </CardContent>
    </Card>
  );
}

export default PrimaryCard;
