import { Box, Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import {
    useParams
} from "react-router-dom"
import GlobalStyles from '../styles/GlobalStyles'
import { getDashBoardData } from "../utilis/api"
import { DASHBOARD_API_ROUTE } from "../utilis/configurations"
import SecondaryCard from './Budget/SecondaryCard'
import Graph from './Graph/Graph'
import PayablesTable from './PayReceiveTable/Payable'
import ReceivablesTable from './PayReceiveTable/Receivable'
import PayReceiveCardTotal from './PayReceiveTotal/PayReceiveCardTotal'

function Home() {

    const [dashboard, setDashboard] = useState([])
    const classes = GlobalStyles();
    const { database } = useParams();

    useEffect(() => {
        async function fetchDashboardData() {
            try {
                const dashboardRes = await getDashBoardData(DASHBOARD_API_ROUTE(database))
                setDashboard(dashboardRes);
            }catch(err){
                throw err;
            }
        }
        fetchDashboardData();

    },[database]);
    
    return (
        <Box className={classes.ibs_p_20}>
            <SecondaryCard dashboard={dashboard}/>
            <div className={classes.ibs_flex_items}>
                <Grid item lg={9} md={12} xl={12} xs={12} >
                    <Graph dashboard={dashboard} database={database}/>   
                </Grid>
           
                <PayReceiveCardTotal dashboard={dashboard} />
            </div>
            <div className={classes.ibs_flex_items}>
                <ReceivablesTable dashboard={dashboard}/>
                <PayablesTable dashboard={dashboard}/>
            </div>
        </Box>
    );
}

export default Home;
