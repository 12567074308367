import { Box } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import MenuIcon from "@material-ui/icons/Menu";
import clsx from "clsx";
import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import LogoSmall from "../../Logo.png";
import GlobalStyles from "../../styles/GlobalStyles";
import routes from '../../utilis/routes';
import MenuItem from "./MenuItem";
import { useStyles } from "./styles";


const Navigation = (props) => {
  const [openDrawer, setOpenDrawer] = useState(true);
  const globalClasses = GlobalStyles();

  const toggleOpenDrawer = () => {
    setOpenDrawer(!openDrawer);
  }
  
  const classes = useStyles();
  return (
    <div className={globalClasses.ibs_flex_height}>
      <Drawer 
        classes={{
          paper: clsx(
            classes.navigationDrawer,
            !openDrawer && classes.navigationDrawerCollapse
            )}}
            open = {openDrawer}
            variant="permanent">
        <div className={clsx(classes.navigationToolBar, !openDrawer && classes.navigationToolBarCollapse)}>
          <IconButton onClick={toggleOpenDrawer}>
            {openDrawer ? <ChevronLeftIcon /> : <MenuIcon />}
          </IconButton>
        </div>
        <Divider />
        <div className={classes.navigationLogoContainer}>
          <img
            width={"100"}
            className={classes.navigationLogo}
            src={openDrawer ? "" : LogoSmall}
            alt=""
          />
        </div>
        <List className={classes.navigationList}>
            {routes().map((item, index) => {
              return (
                <MenuItem key={index}
                  label={item.label}
                  path={item.path}
                  icon={item.icon}
                  activeIcon={item.activeIcon}
                />
              )
            })}
        </List>
      </Drawer>
      <Box style={{width: "100%"}}>
        <Outlet />
      </Box>
    </div>
  );
};

export default Navigation;
