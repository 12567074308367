import { Box } from '@material-ui/core'
import React from 'react'
import GlobalStyles from '../styles/GlobalStyles'
import { CircularProgress } from '@mui/material';
import IBS_Logo from "../IBS_Logo.svg";

const IBSLoadingPage = () => {
  const globalClasses = GlobalStyles();
  return (
    <Box className={`${globalClasses.ibs_vertically_center_container} ${globalClasses.ibs_loading_screen}`}>
        <img alt='logo' className={globalClasses.ibs_mb_30} align="center" width={60} src={IBS_Logo} />
        <CircularProgress style={{color: 'white'}} size={30}/>
    </Box>
  )
}

export default IBSLoadingPage