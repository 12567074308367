import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { logout } from '../../redux/useSlice';
import GlobalStyles from '../../styles/GlobalStyles';
import { useStyles } from "./styles";


const ToolbarComponent = () => { 
  const classes = useStyles();
  const [anchorElUser, setAnchorElUser] = useState(null);
  const email = useSelector(state => state.user.user.name);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const globalClasses = GlobalStyles();
  const {database} = useParams();



  
  const handleCloseUserMenu = () =>{
    setAnchorElUser(null)
  }

  const extractCompanyName = (databaseParam) => {
    let companyNameSelected = databaseParam.match(/[a-zA-Z]/g);
    return companyNameSelected
  }

  const handleSwitchCompany = (event) => {
    event.preventDefault();
    navigate("/manageCompany")
  }

  const handleLogout = (event) =>{
    event.preventDefault();
    dispatch(logout())
    navigate("/")
    toast.success("Successfully logged Out",{ position: "top-right" })
  }
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget)
  }
  return (
    <AppBar position='static' style={{backgroundColor: '#773ab3b6'}}>
        <Container maxWidth='false'>
            <Toolbar className={classes.toolBarContainer}>
              <Box className={globalClasses.ibs_flex_alignment}>
                <Typography className={`${globalClasses.ibs_pl_20} ${globalClasses.ibs_font_weight_md}`}>{extractCompanyName(database)}</Typography>
              </Box>
              <Box className={globalClasses.ibs_flex_alignment}>
                <Box>
                  <Typography className={`${globalClasses.ibs_pr_20} ${globalClasses.ibs_font_weight_md}`}>Hi {email}</Typography>
                </Box>
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt="Profile" src={""} />
                </IconButton>
                <Menu
                  sx={{mt: '45px'}}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  keepMounted
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                
                  <MenuItem onClick={handleCloseUserMenu}>
                    <Typography onClick={handleSwitchCompany} className={`${globalClasses.ibs_flex_alignment} ${globalClasses.ibs_font_size_sm}`}>
                      <ChangeCircleIcon style={{color: '#AF71EC'}}/>
                      <p style={{marginBlock: 5}}>Switch Company</p>
                    </Typography>
                  </MenuItem>

                  <MenuItem onClick={handleCloseUserMenu}>
                    <Typography onClick={handleLogout} className={`${globalClasses.ibs_flex_alignment} ${globalClasses.ibs_font_size_sm}`}>
                      <LogoutIcon style={{color: '#AF71EC'}}/>
                      <p style={{marginBlock: 5}}>LogOut</p>
                    </Typography>
                  </MenuItem>
                </Menu>
              </Box>
            </Toolbar>
        </Container>
    </AppBar>
  )
}

export default ToolbarComponent