import { ThemeProvider } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  Route,
  Routes
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SecondaryCards from "./Accounting/AccountingSecondaryCards/SecondaryCards";
import History from "./Accounting/History/History";
import Statements from "./Accounting/Statements/Statements";
import Transactions from "./Accounting/Transactions/Transactions";
import "./App.css";
import IBSLoadingPage from "./Components/IBSLoadingPage";
import NavigationDrawer from "./Components/Navigation/NavigationDrawer";
import { useStyles } from "./Components/Navigation/styles";
import ToolbarComponent from "./Components/Toolbar/Toolbar";
import Home from "./Home/Home";
import Login from "./Login/Login";
import ManageCompany from "./ManageCompanies/ManageCompany";
import { IBS_THEME } from "./styles/GlobalStyles";

// const { invoke } = window.__TAURI__.tauri
function App() {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    setTimeout(() => setLoading(false), 3000);
  }, []);
  return (
    <React.Fragment>
      {loading === false ? (
        <ThemeProvider theme={IBS_THEME}>
          <div className={`${classes.appRoot} `}>
            <div className="app">
              <Routes>
                <Route exact path="/" element={<Login />} />

                <Route path="*" element={<React.Fragment>Not Found 404</React.Fragment>} />
                <Route
                  exact
                  path="/manageCompany"
                  element={<ManageCompany />}
                />
                <Route
                  path="/dashboard"
                  element={
                    <React.Fragment>
                      <ToolbarComponent />
                      <NavigationDrawer />
                    </React.Fragment>
                  }
                >
                  <Route path="home/:database" element={<Home />} />
                  <Route
                    path="transactions/:database"
                    element={
                      <React.Fragment>
                        <SecondaryCards />
                        <Transactions />
                      </React.Fragment>
                    }
                  />

                  <Route
                    path="history/:database"
                    element={
                      <React.Fragment>
                        <SecondaryCards />
                        <History />
                      </React.Fragment>
                    }
                  />

                  <Route
                    path="statements/:database"
                    element={
                      <React.Fragment>
                        <SecondaryCards />
                        <Statements />
                      </React.Fragment>
                    }
                  />
                </Route>
              </Routes>

              <ToastContainer />
            </div>
          </div>
        </ThemeProvider>
      ) : (
        <IBSLoadingPage />
      )}
    </React.Fragment>
  );
}

export default App;
