import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
    name: "user",
    initialState: {
        user: {
            name: null,
            token: null,
        }
    },
    reducers: {
        login: (state, action) => {
            const { name, token } = action.payload;
            state.user = { name, token };
        },
        logout: (state) => {
            state.user = { name: null, token: null };
        },
    }
});

export const { login, logout } = userSlice.actions;

export const selectUser = (state) => state.user.user;

export default userSlice.reducer;
