import { Box, Card, CardContent, Container, Typography } from '@material-ui/core'
import { Stack, Chip} from '@mui/material';
import React from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import GlobalStyles from '../styles/GlobalStyles';
import { useStyles } from './styles';
import CompanyLogo from '../companyLogo.svg';
import { useEffect } from 'react';
import authService from '../utilis/api';
import { useState } from 'react';

const ManageCompany = () => {
  const globalClasses = GlobalStyles();
  const classes = useStyles();
  const [companies, setCompanies] = useState([]);
  const token = useSelector(state => state.user.user.token);

  useEffect(()=> {
    const getCompaniesLink = async () => {
        try{
            const companyLinkRes = await authService.getCompaniesLink(token);
            if(companyLinkRes.data){
                setCompanies(companyLinkRes.data.user_info.companies)
            }
        }catch(err){
            throw err;
        }
    }
    getCompaniesLink();
  },[token])
  return (
    <Box className={globalClasses.ibs_vertically_center_container}>
    <Container maxWidth={'sm'}>
        <Box>
            <Typography 
                className={`${globalClasses.ibs_typography_header} ${globalClasses.ibs_mb_30}`} 
                color="textPrimary" align="center" variant="h2">
                Which Company You Operating In?
            </Typography>
            
            {companies.length > 0 ? 
            <div className={globalClasses.ibs_row_alignment}>
                
                {companies.map((company) => (
                        <Link className={globalClasses.ibs_link_unset} to={`/dashboard/home/${company.data.database}`}>
                            <Card className={`${globalClasses.ibs_max_card} ${globalClasses.ibs_mr_20}`} >
                                <CardContent className={`${globalClasses.ibs_column_alignment} ${classes.manageContainer}`}>
                                    <img src={CompanyLogo} width={40} alt="companyCardLogo"/>
                                    <Typography color="secondary" className={`${globalClasses.ibs_typography_subtitle} ${globalClasses.ibs_font_weight_md}`}>
                                        {company.data.name}
                                    </Typography>        
                                    <Stack direction="row" spacing={1}>
                                        <Chip className={`${classes.manageContainerItem}`} color='success' label={company.data.user_level} variant="outlined" />
                                    </Stack>
                                </CardContent>
                            </Card>
                        </Link>
                    ))
                }
            </div>
            : "No company Available"}
           
            <Container className={` ${globalClasses.ibs_mt_90}`} maxWidth={'md'}>
                <a style={{float: 'right', color: '#AF71EC'}} 
                    className={`${globalClasses.ibs_link_unset} ${globalClasses.ibs_font_weight_md}`}
                    target='_blank'
                    rel="noreferrer"
                    href="https://integrated-business-solutions.com/signIn">
                    Register Company
                </a>
            </Container>
        </Box>
    </Container>
    </Box>
  )
}

export default ManageCompany