export const REGISTER_SERVER_URL = 'https://main-api.supappbackend.co.za';
export const MAIN_SERVER_URL = 'https://www.client-api.ibsbackend.co.za';
export const DB_NAME = 'company_db';


const HISTORY_API_KEY="B2038686C986DF4DAB3593F142EC1DBEF78E4A27252951B2EF1C5E67F0D9BC73"

// const DELETE_TRANSACTION_API_KEY="40BFDEEC3D2337D427CB6C19696724A74ACE461A6385B66F024BD37855569767"

const INCOME_STATEMENT_API_KEY="343B6A54CAB56A4DC3813DD812990563718F70AB8182E0A2D0413ED9098AAA5C"

// const INVOICE_REPORTS_API_KEY="58FEF1A5EC2C3D97F34BC2EAABE0EB338544420C64CC1401247153CBC4C5D294"

const DASHBOARD_API_KEY="8AA0DC0795857F255B846DBB11A06B793191181E8B066CE3D46355374E3D401E"

const GET_CATEGORY_DATA_API_KEY="24E3C27ADBDBF55AC80623D63E7F81E44F87CDDC43BA217934AD442845A405D0"

const INSERT_CASH_INCOME_API_KEY="CB493F8AE7CC1D683018705F91593488C2F6BED1499396A528AB794DCABF6756"

const INSERT_CASH_EXPENSE_API_KEY="8934C04A151772407E545A5F80B966F35F6AF9017C88701D88ED9C41EEE6DCB9"

const INSERT_RECEIVABLE_INCOME_API_KEY="CABDA2A5DDC215351A3D5237A7130AA49151152F23A38BA3D874F507F4DDE68C"

const INSERT_PAYABLE_EXPENSE_API_KEY="3A634165D14EF1FB532ECB1C030E408E8DAE272FFECA49E1BCF2B1F204660697"

// const INITIAL_ACCOUNTS_API_KEY="FD6D6CCE762458632652883736632BA46E8B83C9DC36646C51DDDFB63986677B"

// const INSERT_INCOME_STREAM_API_KEY="D87C505A22A84BECE7594E712052A453E6DAA19431FF87A87FA6A9BFB9748B8B"

// const INSERT_EXPENSE_STREAMS_API_KEY="7304D36D23E04AC13A88C2540242D1C8E07966E4B7B8238E3F9DBB1FC5DE4D8C"

const INSERT_CASH_ASSET_API_KEY="5A7134743777217A24432646294A404E635266556A586E3272357538782F413F"

const INSERT_CREDIT_ASSET_API_KEY="4E645267556B58703273357638792F423F4528482B4D6251655368566D597133"

const INSERT_HYBRID_API_KEY="5267556B58703273357638792F423F4528482B4D6251655368566D5971337436"

// const INSERT_PAY_LIABILITY_API_KEY="26452948404D635166546A576E5A7234753778214125442A462D4A614E645267"

// const INSERT_PAYABLES_PAYMENT_API_KEY="66556A576E5A7234753778214125442A472D4B6150645367566B597032733576"

// const INSERT_RECEIVABLE_PAYMENT_API_KEY="4A614E645267556B58703273357638792F423F4528472B4B6250655368566D59"

const INSERT_RECEIVE_LIABILITY_API_KEY="7538782F413F4428472B4B6250655368566D5971337436773979244226452948"

// const CREATE_DATABASE_API_KEY = "4B6250655367566B5970337336763979244226452948404D635166546A576D5A"

// const INCOME_STATEMENT_API_KEY = "343B6A54CAB56A4DC3813DD812990563718F70AB8182E0A2D0413ED9098AAA5C"

///////////////////OPERATION API ROUTES ////////////////////
export const LOGIN_URL_ROUTE = `${REGISTER_SERVER_URL}/auth/login`;
export const COMPANIES_LINK = `${REGISTER_SERVER_URL}/ibs_integration_service/agent-companies`
export const DASHBOARD_API_ROUTE = (dataBaseName) => `${MAIN_SERVER_URL}/client_server/dashboard/${dataBaseName}?access_token=${DASHBOARD_API_KEY}`;
export const CATEGORY_API_ROUTE = (dataBaseName) =>`${MAIN_SERVER_URL}/client_server/get_category_data/${dataBaseName}?access_token=${GET_CATEGORY_DATA_API_KEY}`;
export const TRANSACTION_API_ROUTE_RECEIVABLES = (dataBaseName) => `${MAIN_SERVER_URL}/client_server/insert_receivable_income/${dataBaseName}?access_token=${INSERT_RECEIVABLE_INCOME_API_KEY}`;
export const TRANSACTION_API_ROUTE_INCOME = (dataBaseName) =>`${MAIN_SERVER_URL}/client_server/insert_cash_income/${dataBaseName}?access_token=${INSERT_CASH_INCOME_API_KEY}`;
export const TRANSACTION_API_ROUTE_EXPENSE = (dataBaseName) => `${MAIN_SERVER_URL}/client_server/insert_cash_expense/${dataBaseName}?access_token=${INSERT_CASH_EXPENSE_API_KEY}`;
export const TRANSACTION_API_ROUTE_PAYABLES = (dataBaseName) => `${MAIN_SERVER_URL}/client_server/insert_payable_expense/${dataBaseName}?access_token=${INSERT_PAYABLE_EXPENSE_API_KEY}`;
export const TRANSACTION_API_ROUTE_ASSET_CASH = (dataBaseName) => `${MAIN_SERVER_URL}/client_server/insert_cash_asset/${dataBaseName}?access_token=${INSERT_CASH_ASSET_API_KEY}`;

export const TRANSACTION_API_ROUTE_ASSET_HYBRID = (dataBaseName) =>`${MAIN_SERVER_URL}/client_server/insert_hybrid_asset/${dataBaseName}?access_token=${INSERT_HYBRID_API_KEY}`;
export const HISTORY_API_ROUTE = (startDate, endDate, dataBaseName = "company_db") => `${MAIN_SERVER_URL}/client_server/history/${startDate}/${endDate}/${dataBaseName}?access_token=${HISTORY_API_KEY}`;
export const TRANSACTION_API_ROUTE_ASSET_CREDIT = (dataBaseName) =>`${MAIN_SERVER_URL}/client_server/insert_credit_asset/${dataBaseName}?access_token=${INSERT_CREDIT_ASSET_API_KEY}`;
export const TRANSACTION_API_ROUTE_LIABILITY = (dataBaseName) => `${MAIN_SERVER_URL}/client_server/receive_liability/${dataBaseName}?access_token=${INSERT_RECEIVE_LIABILITY_API_KEY}`;
export const INCOME_STATEMENT_API_ROUTE = (startDate, endDate, dataBaseName) => `${MAIN_SERVER_URL}/client_server/income_statement/${startDate}/${endDate}/${dataBaseName}?access_token=${INCOME_STATEMENT_API_KEY}`

///////////////////DELETE API ROUTES/////////////////
export const DELETE_INCOME_API_ROUTE = (dataBaseName) => `${MAIN_SERVER_URL}/client_server/reverse_cash_income/${dataBaseName}?access_token=${INSERT_CASH_INCOME_API_KEY}`;
export const DELETE_EXPENSE_CASH_API_ROUTE = (dataBaseName) => `${MAIN_SERVER_URL}/client_server/reverse_cash_expense/${dataBaseName}?access_token=${INSERT_CASH_EXPENSE_API_KEY}`;
export const DELETE_EXPENSE_PAYABLE_API_ROUTE = (dataBaseName) => `${MAIN_SERVER_URL}/client_server/reverse_payable_expense/${dataBaseName}?access_token=${INSERT_PAYABLE_EXPENSE_API_KEY}`;
export const DELETE_ASSET_CASH_API_ROUTE = (dataBaseName) => `${MAIN_SERVER_URL}/client_server/reverse_cash_asset/${dataBaseName}?access_token=${INSERT_CASH_ASSET_API_KEY}`;
export const DELETE_INCOME_RECEIVABLE_API_ROUTE = (dataBaseName) => `${MAIN_SERVER_URL}/client_server/reverse_receivable_income_income/${dataBaseName}?access_token=${INSERT_RECEIVABLE_INCOME_API_KEY}`;