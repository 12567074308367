import { Button, Card, Typography } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import GlobalStyles from "../../styles/GlobalStyles";
import FlashMessage from "../../utilis/FlashMessage/FlashMessage";
import AdditionalInfo from "./AdditionalInfo";
import "./Transactions.css";
import useStyles from "./styles";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  createAssetCashEntry,
  createAssetCreditEntry,
  createAssetHybridEntry,
  createExpenseCashEntry,
  createIncomeCashEntry,
  createLiabilityEntry,
  createPayablesExpenseEntry,
  createReceivablesIncomeEntry,
  getAccountingCategories,
} from "../../utilis/api";
import { CategoryOne } from "./MenuItemList";

import { useParams } from "react-router-dom";

import { CircularProgress } from "@mui/material";
import moment from "moment";
import {
  CATEGORY_API_ROUTE,
  TRANSACTION_API_ROUTE_ASSET_CASH,
  TRANSACTION_API_ROUTE_ASSET_CREDIT,
  TRANSACTION_API_ROUTE_ASSET_HYBRID,
  TRANSACTION_API_ROUTE_EXPENSE,
  TRANSACTION_API_ROUTE_INCOME,
  TRANSACTION_API_ROUTE_LIABILITY,
  TRANSACTION_API_ROUTE_PAYABLES,
  TRANSACTION_API_ROUTE_RECEIVABLES,
} from "../../utilis/configurations";

function Transactions(props) {
  const classes = useStyles();
  const [description, setDescription] = useState("");
  const [category_1, setCategory1] = useState("");
  const [category_2, setCategory2] = useState("");
  const [category_3, setCategory3] = useState("");
  const [useful_life, setUsefulLife] = useState(null);
  const [credit_provider, setCreditProvider] = useState("");
  const [interest_rate, setInterestRate] = useState("");
  const [compounded, setCompounded] = useState("");
  const [payment_plan, setPaymentPlan] = useState("");
  const [payment_amount, setPaymentAmount] = useState(null);
  const [loan_term, setLoanTerm] = useState(null);
  const [amount_credit, setAmountCredit] = useState("");
  const [amount_cash, setAmountCash] = useState("");
  const [dynamicCategory, setDynamicCategory] = useState([]);
  const [
    name_and_surname_or_company_name,
    setName_and_surname_or_company_name,
  ] = useState("");
  const [email, setEmail] = useState("");
  const [cell_number_or_telephone_number, setCell_number_or_telephone_number] =
    useState("");
  const [address_1, setAddress_1] = useState("");
  const [address_2, setAddress_2] = useState("");
  const [repayment_date, setRepayment_date] = useState(new Date());
  const [city, setCity] = useState("");
  const [amount, setAmount] = useState(null);
  const [province, setProvince] = useState("");
  const [postal_code, setPostal_code] = useState(null);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const globalClasses = GlobalStyles();
  const [severity, setSeverity] = useState("");
  const user = useSelector((state) => state.user.user.name);
  const navigate = useNavigate();
  let { database } = useParams();
  const { promiseInProgress } = usePromiseTracker();

  useEffect(() => {
    getAccountingCategories(CATEGORY_API_ROUTE(database))
      .then((data) => data.json())
      .then((categories) => {
        console.log(categories);
        setDynamicCategory(categories);
      });
  }, [database]);

  const submitAllAPI = (event) => {
    event.preventDefault();
    if (category_1 === "Income" && category_2 === "Bank") {
      handleSubmitIncomeCash();
    } else if (category_1 === "Income" && category_2 === "Petty Cash") {
      handleSubmitIncomeCash();
    } else if (category_1 === "Income" && category_2 === "Receivable") {
      handleSubmitCashReceivable();
    } else if (category_1 === "Expense" && category_2 === "Bank") {
      handleSubmitExpenseCash();
    } else if (category_1 === "Expense" && category_2 === "Petty Cash") {
      handleSubmitExpenseCash();
    } else if (
      category_1 === "Asset" &&
      (category_2 === "Petty Cash" || category_2 === "Bank")
    ) {
      handleSubmitAssetCash();
    } else if (category_1 === "Expense" && category_2 === "Payable") {
      handleSubmitCashPayables();
    } else if (category_1 === "Asset" && category_2 === "Hybrid") {
      handleSubmitAssetHybrid();
    } else if (category_1 === "Asset" && category_2 === "Credit") {
      handleSubmitAssetCredit();
    } else if (category_1 === "Liability" && category_2 === "Receive") {
      handleSubmitLiability();
    }
  };

  const handleSubmitLiability = (event) => {
    const response = trackPromise(
      createLiabilityEntry(
        description,
        category_1,
        category_2,
        amount,
        credit_provider,
        payment_plan,
        payment_amount,
        interest_rate,
        compounded,
        loan_term,
        TRANSACTION_API_ROUTE_LIABILITY(database)
      )
    );
    response.then((data) => {
      setSuccess(true);
      console.log(data);
      if (data.status === 401) {
        setMessage(data.data.message);
        setSeverity("error");
      }
      if (data.status === "passed") {
        setMessage(data.message);
        setSeverity("success");
      }
    });
    setSuccess(false);
  };

  const handleSubmitAssetHybrid = () => {
    const response = trackPromise(
      createAssetHybridEntry(
        description,
        category_1,
        category_2,
        category_3,
        amount,
        useful_life,
        credit_provider,
        interest_rate,
        compounded,
        payment_plan,
        payment_amount,
        loan_term,
        amount_credit,
        amount_cash,
        TRANSACTION_API_ROUTE_ASSET_HYBRID(database)
      )
    );

    response.then((data) => {
      setSuccess(true);
      setSeverity("success");
      setMessage(data.message);
    });
    setSuccess(false);
  };

  const handleSubmitAssetCredit = () => {
    const response = trackPromise(
      createAssetCreditEntry(
        description,
        category_1,
        category_2,
        category_3,
        amount,
        useful_life,
        credit_provider,
        interest_rate,
        compounded,
        payment_plan,
        payment_amount,
        loan_term,
        TRANSACTION_API_ROUTE_ASSET_CREDIT(database)
      )
    );
    response.then((data) => {
      setSuccess(true);
      if (data?.status === 401) {
        setMessage(data.data.detail);
        setSeverity("error");
        return;
      }
      setSeverity("success");

      // setMessage(data.message);
    });
    setSuccess(false);
  };

  const handleSubmitAssetCash = () => {
    const response = trackPromise(
      createAssetCashEntry(
        description,
        category_1,
        category_2,
        category_3,
        amount,
        useful_life,
        TRANSACTION_API_ROUTE_ASSET_CASH(database)
      )
    );
    response.then((data) => {
      setSuccess(true);
      setSeverity("success");
      setMessage(data.message);
    });
    setSuccess(false);
  };

  const handleSubmitCashReceivable = () => {
    const response = trackPromise(
      createReceivablesIncomeEntry(
        description,
        category_1,
        category_2,
        category_3,
        amount,
        name_and_surname_or_company_name,
        address_1,
        user,
        address_2,
        city,
        province,
        postal_code,
        email,
        cell_number_or_telephone_number,
        moment(repayment_date).format("YYYY-MM-DD"),
        TRANSACTION_API_ROUTE_RECEIVABLES(database)
      )
    );
    response
      .then((data) => {
        setSuccess(true);
        if (data?.status === 401) {
          setMessage(data.data.detail);
          setSeverity("error");
          return;
        }
        setSeverity("success");

        setMessage(data.message);
      })
      .catch((err) => {
        console.log(err);
      });
    setSuccess(false);
  };

  const handleSubmitCashPayables = () => {
    const response = trackPromise(
      createPayablesExpenseEntry(
        description,
        category_1,
        category_2,
        category_3,
        amount,
        name_and_surname_or_company_name,
        address_1,
        address_2,
        user,
        city,
        province,
        postal_code,
        email,
        cell_number_or_telephone_number,
        moment(repayment_date).format("YYYY-MM-DD"),
        TRANSACTION_API_ROUTE_PAYABLES(database)
      )
    );
    response.then((data) => {
      setSuccess(true);
      if (data?.status === 401) {
        setMessage(data.data.detail);
        setSeverity("error");
        return;
      }
      setSeverity("success");
      setMessage(data.message);
    });
    setSuccess(false);
  };

  const handleSubmitIncomeCash = () => {
    const response = trackPromise(
      createIncomeCashEntry(
        description,
        category_1,
        category_2,
        category_3,
        amount,
        TRANSACTION_API_ROUTE_INCOME(database)
      )
    );
    response.then((data) => {
      setSuccess(true);
      if (data?.status === 401) {
        setMessage(data.data.detail);
        setSeverity("error");
        return;
      }
      setSeverity("success");
      setMessage(data.message);
    });
    setSuccess(false);
  };

  const handleSubmitExpenseCash = () => {
    const response = trackPromise(
      createExpenseCashEntry(
        description,
        category_1,
        category_2,
        category_3,
        amount,
        TRANSACTION_API_ROUTE_EXPENSE(database)
      )
    );
    response
      .then((data) => {
        setSuccess(true);
        if (data?.status === 401) {
          setMessage(data.data.detail);
          setSeverity("error");
          return;
        }
        setSeverity("success");
        setMessage(data.message);
      })
      .catch(() => {
        setSuccess(false);
      });
  };

  return (
    <Card
      className={`transactions ${globalClasses.ibs_p_20}`}
      style={{ minHeight: "max-height" }}
    >
      <Typography
        className={`${globalClasses.ibs_mb_10} ${globalClasses.ibs_ptl_20} ${globalClasses.ibs_ml_30} ${globalClasses.ibs_font_weight_md}`}
        style={{ color: "#5c5b5f" }}
      >
        Transaction Entries
      </Typography>

      <div className="transaction__input">
        <form autoComplete="off" onSubmit={submitAllAPI}>
          <div className="transaction__form">
            <TextField
              onChange={(event) => setDescription(event.target.value)}
              label="Description"
              multiline
              color="secondary"
              columns={9}
              variant="filled"
            />

            <FormControl
              className={globalClasses.ibs_margin_unset}
              color="secondary"
              variant="filled"
              sx={{ m: 1, minWidth: 150 }}
            >
              <InputLabel>Category 1</InputLabel>
              <Select onChange={(event) => setCategory1(event.target.value)}>
                {CategoryOne.map((category) => (
                  <MenuItem value={category}>{category}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl
              className={globalClasses.ibs_margin_unset}
              variant="filled"
              color="secondary"
              sx={{ m: 1, minWidth: 150 }}
            >
              <InputLabel>Category 2</InputLabel>
              {(() => {
                switch (category_1) {
                  case "Income":
                    return (
                      <Select
                        onChange={(event) => setCategory2(event.target.value)}
                      >
                        <MenuItem value="Receivable">Receivables</MenuItem>
                        <MenuItem value="Bank">Bank</MenuItem>
                        <MenuItem value="Petty Cash">Petty Cash</MenuItem>
                      </Select>
                    );
                  case "Expense":
                    return (
                      <Select
                        onChange={(event) => setCategory2(event.target.value)}
                      >
                        <MenuItem value="Payable">Payables</MenuItem>
                        <MenuItem value="Bank">Bank</MenuItem>
                        <MenuItem value="Petty Cash">Petty Cash</MenuItem>
                      </Select>
                    );
                  case "Asset":
                    return (
                      <Select
                        onChange={(event) => setCategory2(event.target.value)}
                      >
                        <MenuItem value="Bank">Bank</MenuItem>
                        <MenuItem value="Credit">Credit</MenuItem>
                        <MenuItem value="Hybrid">Hybrid</MenuItem>
                      </Select>
                    );
                  case "Liability":
                    return (
                      <Select
                        onChange={(event) => setCategory2(event.target.value)}
                      >
                        <MenuItem value="Receive">Receive</MenuItem>
                      </Select>
                    );
                  default:
                    return (
                      <Select
                        onChange={(event) => setCategory2(event.target.value)}
                      ></Select>
                    );
                }
              })()}
            </FormControl>

            <FormControl
              className={globalClasses.ibs_margin_unset}
              variant="filled"
              color="secondary"
              sx={{ m: 1, minWidth: 150 }}
            >
              <InputLabel className={""}>Category 3</InputLabel>
              {(() => {
                switch (category_1) {
                  case "Income":
                    return (
                      <Select
                        onChange={(event) => setCategory3(event.target.value)}
                      >
                        {dynamicCategory.length != 0 ? (
                          dynamicCategory["incomes"].map((income) => (
                            <MenuItem value={income}>{income}</MenuItem>
                          ))
                        ) : (
                          <MenuItem value=""></MenuItem>
                        )}
                      </Select>
                    );
                  case "Expense":
                    return (
                      <Select
                        onChange={(event) => setCategory3(event.target.value)}
                        required
                      >
                        {dynamicCategory.length != 0 ? (
                          dynamicCategory["expenses"].map((expense) => (
                            <MenuItem value={expense}>{expense}</MenuItem>
                          ))
                        ) : (
                          <MenuItem value=""></MenuItem>
                        )}
                      </Select>
                    );
                  case "Asset":
                    return (
                      <Select
                        required
                        onChange={(event) => setCategory3(event.target.value)}
                      >
                        {dynamicCategory.length != 0 ? (
                          dynamicCategory["assets"].map((asset) => (
                            <MenuItem value={asset}>{asset}</MenuItem>
                          ))
                        ) : (
                          <MenuItem value=""></MenuItem>
                        )}
                      </Select>
                    );
                  case "Liability":
                    return (
                      <Select
                        disabled
                        onChange={(event) => setCategory3(event.target.value)}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                      </Select>
                    );
                  default:
                    return (
                      <Select
                        required
                        className={classes.selectEmpty}
                        onChange={(event) => setCategory3(event.target.value)}
                      ></Select>
                    );
                }
              })()}
            </FormControl>
            <TextField
              label="Amount"
              onChange={(event) => setAmount(event.target.value)}
              multiline
              color="secondary"
              columns={2}
              variant="filled"
              value={amount}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">R</InputAdornment>
                ),
              }}
            />
          </div>
          <div className="additional__infoNote">
            {(() => {
              switch (category_2) {
                case "Receivable":
                case "Payable":
                  return (
                    <AdditionalInfo
                      category2={category_2}
                      name={name_and_surname_or_company_name}
                      setName={setName_and_surname_or_company_name}
                      setEmail={setEmail}
                      email={email}
                      setRepay={setRepayment_date}
                      repay={repayment_date}
                      cell={cell_number_or_telephone_number}
                      setCell={setCell_number_or_telephone_number}
                      addressOne={address_1}
                      setAddressOne={setAddress_1}
                      addressTwo={address_2}
                      setAddressTwo={setAddress_2}
                      city={city}
                      setCity={setCity}
                      province={province}
                      setProvince={setProvince}
                      postalCode={postal_code}
                      setPostalCode={setPostal_code}
                      user={user}
                    />
                  );
                case "Bank":
                  if (category_1 === "Asset") {
                    return (
                      <AdditionalInfo
                        category2={category_2}
                        category_1={category_1}
                        usefulLife={useful_life}
                        setUsefulLife={setUsefulLife}
                      />
                    );
                  } else {
                    return "";
                  }
                case "Credit":
                  return (
                    <AdditionalInfo
                      category2={category_2}
                      usefulLife={useful_life}
                      setUsefulLife={setUsefulLife}
                      creditProvider={credit_provider}
                      setCreditProvider={setCreditProvider}
                      interestRate={interest_rate}
                      setInterestRate={setInterestRate}
                      compounded={compounded}
                      setCompounded={setCompounded}
                      paymentPlan={payment_plan}
                      setPaymentPlan={setPaymentPlan}
                      paymentAmount={payment_amount}
                      setPaymentAmount={setPaymentAmount}
                      loanTerm={loan_term}
                      setLoanTerm={setLoanTerm}
                    />
                  );
                case "Receive":
                  return (
                    <AdditionalInfo
                      category2={category_2}
                      creditProvider={credit_provider}
                      setCreditProvider={setCreditProvider}
                      interestRate={interest_rate}
                      setInterestRate={setInterestRate}
                      compounded={compounded}
                      setCompounded={setCompounded}
                      paymentPlan={payment_plan}
                      setPaymentPlan={setPaymentPlan}
                      paymentAmount={payment_amount}
                      setPaymentAmount={setPaymentAmount}
                      loanTerm={loan_term}
                      setLoanTerm={setLoanTerm}
                    />
                  );
                case "Hybrid":
                  return (
                    <AdditionalInfo
                      category2={category_2}
                      usefulLife={useful_life}
                      setUsefulLife={setUsefulLife}
                      creditProvider={credit_provider}
                      setCreditProvider={setCreditProvider}
                      interestRate={interest_rate}
                      setInterestRate={setInterestRate}
                      compounded={compounded}
                      setCompounded={setCompounded}
                      paymentPlan={payment_plan}
                      setPaymentPlan={setPaymentPlan}
                      paymentAmount={payment_amount}
                      setPaymentAmount={setPaymentAmount}
                      loanTerm={loan_term}
                      setLoanTerm={setLoanTerm}
                      amountCash={amount_cash}
                      setAmountCash={setAmountCash}
                      amountCredit={amount_credit}
                      setAmountCredit={setAmountCredit}
                    />
                  );
                default:
                  return "";
              }
            })()}
          </div>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submitButton}
          >
            {promiseInProgress === true ? (
              <CircularProgress
                sx={{
                  color: "#fff",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
                size={16}
              />
            ) : null}
            Done
          </Button>
        </form>
        {success ? <FlashMessage message={message} severity={severity} /> : ""}
      </div>
    </Card>
  );
}

export default Transactions;
