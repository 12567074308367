import { 
    errorHandlingObj,
    messageToastResponseObj, 
    severityObj
} from "./constants"


export const errorHandling = (error, setSeverity, setMessage) => {
    if(error.message === errorHandlingObj.NETWORK_ERR){
        setMessage(messageToastResponseObj.NETWORK_ERR_MESSAGE)
        setSeverity(severityObj.WARNING);
        return;
    }else if(error.message === errorHandlingObj.UNAUTH_USER){
        setMessage(messageToastResponseObj.UNAUTH_USER_MESSAGE)
        setSeverity(severityObj.ERROR)
        return;
    }
}

