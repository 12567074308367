import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";
import useStyles from "./styles";
import GlobalStyles from "../../styles/GlobalStyles";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


function AdditionalInfo(props) {
  const classes = useStyles();
  const globalClasses = GlobalStyles();

  const timePeriods = ["Monthly", "Quarterly", "Semi-Annually", "Yearly", "Biennial"]
  return (
    <div className={classes.AdditionalText}>
      <Typography
        className={` ${globalClasses.ibs_mb_10} ${globalClasses.ibs_font_weight_md}`}
        style={{color: "#5c5b5f"}}
      >
        Additional Information
      </Typography>

      {props.category2 === "Bank" ? (
        <TextField
          onChange={event => props.setUsefulLife(event.target.value)}
          className={classes.AdditionalFields}
          label="Useful Life (months)"
          color="secondary"
          variant="filled"
          value={props.usefulLife}
          columns={2}
         
        />
      ) : (
        ""
      )}

      {props.category2 === "Hybrid" ? (
        <Box className={globalClasses.ibs_grid_columns_4}>
          <TextField
            onChange={event => props.setUsefulLife(event.target.value)}
            className={classes.AdditionalFields}
            label="Useful Life (months)"
            value={props.usefulLife}
            color="secondary"
            variant="filled"
            columns={2}
          />
          <TextField
            onChange={event => props.setCreditProvider(event.target.value)}
            className={classes.AdditionalFields}
            label="Credit Provider"
            value={props.creditProvider}
            color="secondary"
            variant="filled"
            columns={2}
          />

          <TextField
            onChange={event => props.setInterestRate(event.target.value)}
            className={classes.AdditionalFields}
            label="Interest rate(%)"
            variant="filled"
            color="secondary"
            value={props.interestRate}
            columns={2}
          />
          <TextField
          select
          sx={{m: 1, minWidth: 150}}
          label="Compounded"
          value={props.compounded}
          color="secondary"
          variant="filled"
          onChange={event => props.setCompounded(event.target.value)}
        >
          {timePeriods.map(option => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>

          <TextField
            select
            sx={{m: 1, minWidth: 150}}
            label="Payment Plan"
            value={props.paymentPlan}
            color="secondary"
            variant="filled"
            onChange={event => props.setPaymentPlan(event.target.value)}
          >
            {timePeriods.map(option => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            onChange={event => props.setPaymentAmount(event.target.value)}
            className={classes.AdditionalFields}
            label="Payment Amount"
            color="secondary"
            value={props.paymentAmount}
            variant="filled"
            columns={2}
          />

          <TextField
            onChange={event => props.setAmountCash(event.target.value)}
            className={classes.AdditionalFields}
            label=" Cash Amount"
            color="secondary"
            value={props.amount_cash}
            variant="filled"
            columns={2}
          />

          <TextField
            onChange={event => props.setAmountCredit(event.target.value)}
            className={classes.AdditionalFields}
            label=" Credit Amount"
            color="secondary"
            value={props.amount_credit}
            variant="filled"
            columns={2}
          />

          <TextField
            onChange={event => props.setLoanTerm(event.target.value)}
            className={classes.AdditionalFields}
            label="Loan Term(months)"
            value={props.loanTerm}
            color="secondary"
            variant="filled"
            columns={2}
          />
        </Box>
      ) : (
        ""
      )}

      {props.category2 === "Receive" ? (
        <Box className={`${globalClasses.ibs_grid_columns_3} ${globalClasses.ibs_mb_20} ${globalClasses.ibs_gap_10}`}>
          <TextField
            onChange={event => props.setCreditProvider(event.target.value)}
            className={classes.AdditionalFields}
            label="Creditor"
            value={props.creditProvider}
            color="secondary"
            variant="filled"
            columns={2}
          />

          <TextField
            onChange={event => props.setInterestRate(event.target.value)}
            className={classes.AdditionalFields}
            label="Interest rate(%)"
            value={props.interestRate}
            color="secondary"
            variant="filled"
            columns={2}
          />

          <TextField
            select
            sx={{m: 1, minWidth: 150}}
            label="Payment Plan"
            value={props.paymentPlan}
            color="secondary"
            variant="filled"
            onChange={event => props.setPaymentPlan(event.target.value)}
        >
          {timePeriods.map(option => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          select
          sx={{m: 1, minWidth: 150}}
          label="Compounded"
          value={props.compounded}
          color="secondary"
          variant="filled"
          onChange={event => props.setCompounded(event.target.value)}
        >
        {timePeriods.map(option => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>

        <TextField
          onChange={event => props.setPaymentAmount(event.target.value)}
          className={classes.AdditionalFields}
          label="Payment Amount"
          value={props.paymentAmount}
          color="secondary"
          variant="filled"
          columns={2}
        />

        <TextField
          onChange={event => props.setLoanTerm(event.target.value)}
          className={classes.AdditionalFields}
          label="Loan Term(months)"
          value={props.loanTerm}
          color="secondary"
          variant="filled"
          columns={2}
        />

        </Box>
      ) : ""}
    
      {props.category2 === "Credit" ? (
        <Box className={`${globalClasses.ibs_grid_columns_4} ${globalClasses.ibs_mb_20} ${globalClasses.ibs_gap_10}`}>
          <TextField
            onChange={event => props.setUsefulLife(event.target.value)}
            className={classes.AdditionalFields}
            label="Useful Life (months)"
            value={props.usefulLife}
            color="secondary"
            variant="filled"
            columns={2}
          />

          <TextField
            onChange={event => props.setCreditProvider(event.target.value)}
            className={classes.AdditionalFields}
            label="Credit Provider"
            color="secondary"
            value={props.creditProvider}
            variant="filled"
            columns={2}
          />

          <TextField
            onChange={event => props.setInterestRate(event.target.value)}
            className={classes.AdditionalFields}
            label="Interest rate (%)"
            color="secondary"
            variant="filled"
            value={props.interestRate}
            columns={2}
          />
          <TextField
          select
          sx={{m: 1, minWidth: 150}}
          label="Compounded"
          value={props.compounded}
          color="secondary"
          variant="filled"
          onChange={event => props.setCompounded(event.target.value)}
        >
          {timePeriods.map(option => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>

          <TextField
            select
            sx={{m: 1, minWidth: 150}}
            label="Payment Plan"
            value={props.paymentPlan}
            color="secondary"
            variant="filled"
            onChange={event => props.setPaymentPlan(event.target.value)}
          >
            {timePeriods.map(option => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            onChange={event => props.setPaymentAmount(event.target.value)}
            className={classes.AdditionalFields}
            label="Payment Amount"
            color="secondary"
            value={props.paymentAmount}
            variant="filled"
            columns={2}
          />

          <TextField
            onChange={event => props.setLoanTerm(event.target.value)}
            className={classes.AdditionalFields}
            label="Loan Term (months)"
            value={props.loanTerm}
            color="secondary"
            variant="filled"
            columns={2}
          />
        </Box>
      ) : (
        ""
      )}
      {props.category2 === "Receivable" || props.category2 === "Payable" ? (
        <React.Fragment>
          <div className={globalClasses.ibs_grid_columns_3}>
            <TextField
              onChange={event => props.setName(event.target.value)}
              className={`${classes.AdditionalFields}`}
              label="Company Name"
              color="secondary"
              value={props.name}
              variant="filled"
              columns={2}
            />
            <TextField
              onChange={event => props.setEmail(event.target.value)}
              className={`${classes.AdditionalFields}`}
              label="Email"
              color="secondary"
              value={props.email}
              variant="filled"
              columns={2}
            />
            
            <TextField
              onChange={event => props.setAddressOne(event.target.value)}
              label="Address One"
              className={`${classes.AdditionalFields}`}
              variant="filled"
              value={props.addressOne}
              color="secondary"
              columns={2}
            />

            <TextField
              onChange={event => props.setAddressTwo(event.target.value)}
              label="Address Two"
              className={`${classes.AdditionalFields}`}
              variant="filled"
              value={props.addressTwo}
              color="secondary"
              columns={2}
            />

            <TextField
              onChange={event => props.setCity(event.target.value)}
              label="City"
              className={`${classes.AdditionalFields}`}
              variant="filled"
              value={props.city}
              color="secondary"
              columns={2}
            />

            <TextField
              onChange={event => props.setProvince(event.target.value)}
              label="Province"
              className={`${classes.AdditionalFields}`}
              variant="filled"
              value={props.province}
              color="secondary"
              columns={2}
            />
          
            <TextField
              onChange={event => props.setCell(event.target.value)}
              label="Cell number"
              className={`${classes.AdditionalFields}`}
              variant="filled"
              value={props.cell}
              color="secondary"
              columns={2}
            />

            <TextField
              onChange={event => props.setPostalCode(event.target.value)}
              label="Postal Code"
              className={`${classes.AdditionalFields}`}
              variant="filled"
              value={props.postalCode}
              color="secondary"
              columns={2}
            />

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Start Date"
                  inputFormat="YYYY-MM-DD" 
                  value={props.repay}
                  onChange={(newValue) => {
                    props.setRepay(newValue.$d)
                  }}
                  renderInput={(params) => <TextField variant="filled" color="secondary" {...params} />}
                />
              </LocalizationProvider>
          </div>
        </React.Fragment>
      ) : (
        ""
      )}
    </div>
  );
}

export default AdditionalInfo;
