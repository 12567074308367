import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Container,
  Typography
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { FilledInput, FormControl, IconButton, InputAdornment, InputLabel, TextField } from '@mui/material';
import React, { useState } from "react";
import { useForm } from 'react-hook-form';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import FlashMessage from "../Components/FlashMessage";
import Logo from "../Logo.png";
import { login } from "../redux/useSlice";
import GlobalStyles from "../styles/GlobalStyles";
import authService from "../utilis/api";
import { errorHandling } from "../utilis/errorHandling";
import { validationSchemaLogin } from "../utilis/validation";
import useStyles from "./styles";

const Login = () => {
  const classes = useStyles();
  const globalClasses = GlobalStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(true);
  const [success, setSuccess] = useState(true);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const Login = async() => {
    try{
      const response = await authService.signIn(email, password);
      const token = response.data.access_token;
      if(token){
        setSuccess(true);
        dispatch(
          login({
            name: email,
            token :token
          })
        )
        navigate("/manageCompany");
      }
    }catch(err){
      setSuccess(false);
      errorHandling(err, setSeverity, setMessage);
    }
  };

  const{
    register,
    handleSubmit,
    formState: {errors}
    } = useForm({ 
    resolver: yupResolver(validationSchemaLogin)
  })

  return (
    <Box className={globalClasses.ibs_vertically_center_container}>
    <Container maxWidth={'sm'}>
      <Box>
          <div className={classes.text}>
          <img align="center" alt="logo" className={classes.image} src={Logo} />
            <Typography
              className={`${globalClasses.ibs_typography_header} ${globalClasses.ibs_margin_unset} ${globalClasses.ibs_mb_20}`}
              color="textPrimary"
              variant="h2"
            >
              Sign In With Your IBS Account
            </Typography>
          
        </div>
        <Typography align="center" className={`${globalClasses.ibs_mb_10}`} color="textSecondary" variant="body1">
          Welcome to the IBS Accounting Software
        </Typography>

        <form onSubmit={handleSubmit(Login)}>
          <TextField
            fullWidth
            label="Email Address"
            name="email"
            value={email}
            {...register('email')}
            error={errors.email ? true : false}
            color="secondary"
            type="email"
            variant="filled"
            onChange={event => setEmail(event.target.value)}
          />
          <Typography className={`${globalClasses.ibs_mb_20}`} color="error"> {errors.email?.message} </Typography>
          <FormControl fullWidth variant="filled">
            <InputLabel color="secondary" id="filled-adornment-password">Password</InputLabel>
            <FilledInput
              id="filled-adornment-password"
              fullWidth
              label="Password"
              name="Password"
              {...register('password')}
              error={errors.password ? true : false}
              color="secondary"
              value={password}
              type={showPassword ? "password" : "text"}
              onChange={event => setPassword(event.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton aria-label="toggle password visibility" edge="end" onClick={()=>setShowPassword(!showPassword)}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              variant="filled"
            />
            <Typography color="error" className={`${globalClasses.ibs_mb_20}`}>
              {errors.password?.message}
            </Typography>
          </FormControl>
        
          <Button
            color="primary"
            fullWidth
            size="large"
            type="submit"
            variant="contained"
          >
            Sign in now
          </Button>
          <Typography color="textPrimary">
            Don&apos;t have an account?{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://integrated-business-solutions.com/signUp"
              variant="h6"
            >
              Sign up
            </a>
          </Typography>
        </form>
        <div >
          {!success && <FlashMessage message={message} severity={severity}/>}
        </div>
      </Box>
    </Container>
    </Box>
  );
};

export default Login;
