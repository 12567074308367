import React, { useState, useEffect } from 'react'
import {Icon, ListItem, ListItemIcon, ListItemText} from "@material-ui/core"
import { useStyles } from './styles'
import clsx from "clsx";
import { Link, useLocation } from 'react-router-dom';

const MenuItem = ({label, icon, activeIcon, path}) => {
    const [active, setActive] = useState(true);
    const classes = useStyles();
    const location = useLocation();
    const BASE_COLOR = "#AF71EC";

    useEffect(() =>{
      setActive(location.pathname === path)
    }, [location, path])

  return (
    <ListItem button
        component={Link}
        to={path}
        className={clsx(classes.menuItem, active && classes.menuItemActive)}
        >
        <ListItemIcon>
            <Icon>
                {active ? activeIcon : icon}
            </Icon>
        </ListItemIcon>
        <ListItemText style={{color: active ? BASE_COLOR : 'grey'}} primary={label} primaryTypographyProps={{variant: "body2"}}/>
    </ListItem>
  )
}

export default MenuItem