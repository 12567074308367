import { Card, CardContent, Typography } from '@material-ui/core';
import { Box } from '@mui/material';
import axios from 'axios';
import React, { Component } from 'react';
import { Bar } from "react-chartjs-2";
import {
  DASHBOARD_API_ROUTE
} from "../../utilis/configurations";

class Graph extends Component {

  constructor(){
    super();
    this.state = {
      chartData:{}
    }
  }
  componentDidMount() {
    this.getChartData();
  }
  getChartData() {
    axios.get(DASHBOARD_API_ROUTE(this.props.database)).then(response => {
        const incomeData = response.data.income_list;
        const dateData = response.data.dates
        const expenseData = response.data.expenses_list;
        const profitData = response.data.profit_list;
      
        this.setState({
          chartData: {
            labels: dateData,
            datasets: [
              {
                label: "Income",
                data: incomeData,
                backgroundColor: "#b5b3b3"
              },
              {
                label: "Expense",
                data: expenseData,
                backgroundColor: "#e85a8d"
              },
              {
                label: "Profit",
                data: profitData,
                backgroundColor: "#ad8fdb"
              },
            ]
          }
        });
      });
  }

  render(){
    
        return (
          <Card style={{ marginBottom: "20px"}}>
           <Typography variant="body2" gutterBottom
            style={{padding: "20px", fontWeight: "600"}}
           >
            Latest Sales
           </Typography>

            <CardContent>
              <Box
                sx={{
                  height: 387,
                  position: 'relative'
                }}
              >
              {Object.keys(this.state.chartData).length ?
                  <Bar
                    data={this.state.chartData}
                    options={{
                      animation: true,
                      layout: { padding: 0 },
                      legend: { display: false },
                      maintainAspectRatio: false,
                      responsive: true,
                    }}
                  />
                : null}
              </Box>
            </CardContent>
            
          </Card>
        );
    }     
}

export default Graph;