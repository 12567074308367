import * as Yup from "yup";

export const validationSchemaLogin = Yup.object().shape({
    email: Yup.string()
      .email("Enter a valid email")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
});

export const validationSchemaTransactionEntries = Yup.object().shape({
  CategoryOneEntry: Yup.string()
    .required("Category is required"),
  CategoryTwoEntry: Yup.string()
    .required("Category is required"),
  CategoryThreeEntry: Yup.string()
    .required("Category is required"),
  Amount: Yup.number("Amount is required")
    .positive("Amount is positive")
    .min(1)
    .required("Amount is required")
})