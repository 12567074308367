import React, { useState, useEffect } from "react";

import {
  Page,
  Text,
  Document,
  PDFViewer,
  PDFDownloadLink,
} from "@react-pdf/renderer";

import IncomeStatementTemplate from "./IncomeStatement";

import {
  Box,
  Card,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@material-ui/core";
import { TextField } from "@mui/material";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DownloadIcon from "@mui/icons-material/Download";
import GlobalStyles from "../../styles/GlobalStyles";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  useParams
} from "react-router-dom";
import { getIncomeStatementData } from "../../utilis/api";
import * as moment  from 'moment';

function App() {
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const {database} = useParams();
  const globalClasses = GlobalStyles();

  useEffect(() => {
    getIncomeStatementData(moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'), database)
    .then((data) => data.json())
    .then((response) => {
      setData(response)
      console.log(response)
    })
  }, [startDate, endDate]);


  const getfileName = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Month is 0-based, so we add 1 and pad with 0 if needed
    const day = String(today.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    
    return `Income_Statement_${formattedDate}`
  }

  // PDF document component
  const PDFDocument = () => <IncomeStatementTemplate incomeData={data}/>;

  return (
    <Card className="transactions">
      <Typography
        className={`${globalClasses.ibs_mb_4} 
        ${globalClasses.ibs_ptl_20} 
        ${globalClasses.ibs_ml_30} 
        ${globalClasses.ibs_typography_color} 
        ${globalClasses.ibs_font_weight_md}`}
      >
        Statements
      </Typography>

      <Box display={'flex'} className={globalClasses.ibs_ml_30}>
        <Box mr={2}>
          <LocalizationProvider className={globalClasses.ibs_mr_20} dateAdapter={AdapterDayjs}>
            <DatePicker 
              label="Start Date"
              inputFormat="YYYY-MM-DD"
              value={startDate}
              color='secondary'
              onChange={(newValue) => {
                setStartDate(newValue.$d)
              }}
              renderInput={(params) => <TextField className={globalClasses.ibs_mr_20} variant="filled" color="secondary" {...params} />}
            />
          </LocalizationProvider>
        </Box>
       
        <Box>
          <LocalizationProvider className={globalClasses.ibs_ml_20} dateAdapter={AdapterDayjs}>
            <DatePicker 
              label="End Date"
              inputFormat="YYYY-MM-DD"
              value={endDate}
              onChange={(newValue) => {
                setEndDate(newValue.$d)
              }}
              renderInput={(params) => <TextField variant="filled" color="secondary" {...params} />}
            />
          </LocalizationProvider>
        </Box>
        
      </Box>
      

      <Grid
        className={`${globalClasses.ibs_ptl_20} ${globalClasses.ibs_ml_30}`}
        container
        spacing={6}
      >
        <Grid xs item>
          <Typography className={`${globalClasses.ibs_typography_color}`}>
            type
          </Typography>
        </Grid>
        <Grid xs item>
          <Typography className={`${globalClasses.ibs_typography_color}`}>
            Statement
          </Typography>
        </Grid>
        <Grid xs item>
          <Typography className={`${globalClasses.ibs_typography_color}`}>
            Status
          </Typography>
        </Grid>
        <Grid xs item>
          <Typography className={`${globalClasses.ibs_typography_color}`}>
            Download
          </Typography>
        </Grid>
      </Grid>
      <Grid
        className={`${globalClasses.ibs_mb_4} ${globalClasses.ibs_ptl_20} ${globalClasses.ibs_ml_30}`}
        container
        spacing={6}
      >
        <Grid xs item>
          <Chip color="primary" label={"PDF"} />
        </Grid>

        <Grid container direction="row" xs item>
          <Grid item>
            <PictureAsPdfIcon className={`${globalClasses.ibs_typography_color}`} />
          </Grid>
          <Grid item>
            <Typography
              className={`${globalClasses.ibs_typography_color} ${globalClasses.ibs_font_weight_md}`}
            >
              Income Statement
            </Typography>
          </Grid>
        </Grid>

        <Grid xs item>
          <Chip variant="outlined" color="primary" label={"active"} />
        </Grid>
        <Grid xs item>
          <PDFDownloadLink document={<PDFDocument />} fileName='Income_Statement.pdf'>
            {({ blob, url, loading, error }) => (
              <IconButton>
                {loading ? (
                  <CircularProgress style={{ color: "white" }} size={30} />
                ) : (
                  <DownloadIcon />
                )}
              </IconButton>
            )}
          </PDFDownloadLink>
        </Grid>
      </Grid>
    </Card>
  );
}

export default App;
