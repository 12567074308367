import {  createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => createStyles({

    manageContainer: {
        position: "relative",
        height: "150px",
    },
    manageContainerItem: {
        position: "absolute",
        bottom: "0",
        right: "15px"
    }
}))