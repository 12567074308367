import axios from "axios";

import { 
   LOGIN_URL_ROUTE, 
    INCOME_STATEMENT_API_ROUTE,
    COMPANIES_LINK
} from "./configurations";

class AuthService{

    static getBasicAuth(email, password){
        return window.btoa(
            `${email}:${password}`
        )
    }

    async getCompaniesLink(token){
        try{
          const res = await axios.get(
            COMPANIES_LINK,
            {
              headers: {
                Authorization: `Bearer ${token}`
              }
            }
          )
          return res
        }catch(err){
          throw err
        }
    }

    async signIn(email, password){
        try{
            const basicAuth = AuthService.getBasicAuth(email, password)
            const res = axios.post(
                LOGIN_URL_ROUTE, {},
                {
                    headers: {
                        Authorization: `Basic ${basicAuth}`
                    }
                }
            )
            localStorage.setItem('token', JSON.stringify(res.data))

            return res
        }catch(err){
            throw err;
        }
    }
}
export const SignIn = async (email, password) => {
    const response = await axios.post(LOGIN_URL_ROUTE, {
        email: email,
        password: password
    })
    return response.data;
}

export const getDashBoardData = async (dashboardBaseUrl) => {
    const response = await axios.get(dashboardBaseUrl);
    return response;
}

export const getAccountingCategories = (categoryBaseUrl) => {
    const response = fetch(categoryBaseUrl);
    return response;
}

export const getIncomeStatementData = (startDate, endDate, dataBaseName) => {
    const response = fetch(INCOME_STATEMENT_API_ROUTE(startDate, endDate, dataBaseName));
    return response
}

export const createExpenseCashEntry = async(description, category_1, category_2, category_3, amount, expenseBaseUrl) => {
    try{
        const response = await axios.post(expenseBaseUrl, {
            description: description,
            category_1: category_1,
            category_2: category_2,
            category_3: category_3,
            amount: amount
        })
        return response.data;
    }catch(error){
        return error.response;
    }
}

export const reverseCashIncome = async(transaction_id, category_1, category_2, amount,
     reverseCashIncomeBaseUrl)  => {
    const reverseIncomeData = {
        "transaction_id": transaction_id,
       "category_1": category_1,
        "category_2": category_2,
        "amount": amount
    }
    try{
        const response = await axios.delete(reverseCashIncomeBaseUrl, {
            data: reverseIncomeData
        })
        // console.log(response.data)
        return response.data
    }catch(error){
        return error.response;
    }
}

export const reverseExpensePayables = async(transaction_id, category_1, category_2, amount, reverseExpensePayableBaseUrl) =>{
    const reverseExpensePayablesData = {
        "transaction_id": transaction_id,
        "category_1": category_1,
        "category_2": category_2,
        "amount": amount
    }
    try{
        const response = await axios.delete(reverseExpensePayableBaseUrl, {
            data: reverseExpensePayablesData
        })
        return response.data;
    }catch(error){
        return error.response;
    }
}

export const reverseReceivableIncome = async(transaction_id, category_1, category_2, amount,
     reverseReceivableIncomeBaseUrl) =>{
    const reverseReceivableIncomeData ={ 
        "transaction_id": transaction_id,
        "category_1": category_1,
        "category_2": category_2,
        "amount": amount
    }
    try{
        const response = await axios.delete(reverseReceivableIncomeBaseUrl, {
            data: reverseReceivableIncomeData
        })
        return response.data;
    }catch(error){
        return error.response;
    }
}

export const reverseAssetCash = async(transaction_id, category_1, category_2, amount,
    reverseAssetCashBaseUrl) => {
        const reverseAssetCash = {
            "transaction_id": transaction_id,
            "category_1": category_1,
            "category_2": category_2,
            "amount": amount
        }
        try{
            const response = await axios.delete(reverseAssetCashBaseUrl,{
                data: reverseAssetCash
            })
            return response.data
        }catch(error){
            return error.response;
        }
}

export const reverseCashExpense = async(transaction_id, category_1, category_2, amount, 
    reverseCashExpenseBaseUrl) => {
        const reverseExpenseData = {
            "transaction_id": transaction_id,
            "category_1": category_1,
             "category_2": category_2,
             "amount": amount
        }
    try{
        const response = await axios.delete(reverseCashExpenseBaseUrl, {
            data: reverseExpenseData
        })
        return response.data
    }catch(error){
        return error.response;
    }
}

export const getTransactionHistory = (historyBaseUrl) =>{
    // console.log(HISTORY_API_ROUTE(startDate, endDate));
    const response = fetch(historyBaseUrl);
    return response;
}

export const createIncomeCashEntry = async(
    description, category_1, category_2, category_3, amount, incomeBaseUrl
) => {
    try{
        const response = await axios.post(incomeBaseUrl, {
            description: description,
            category_1: category_1,
            category_2: category_2,
            category_3: category_3,
            amount: amount
        })
        return response.data;
    }catch(error){
        return error.response;
    }
}

export const createAssetCashEntry = async(
    description, category_1, category_2, category_3, amount, useful_life, assetCashBaseUrl) => {
        try{
            const response = await axios.post(assetCashBaseUrl, {
                description: description,
                category_1: category_1,
                category_2: category_2,
                category_3: category_3,
                amount: amount,
                useful_life: useful_life
            })
            return response.data;
        }catch(error){
            return error.response;
        }
}


export const createAssetCreditEntry = async(
    description, category_1, category_2, category_3, amount,
    useful_life, credit_provider, interest_rate, compounded, payment_plan, payment_amount, loan_term, assetCreditBaseUrl
)=> {
    try{
        const response = await axios.post(assetCreditBaseUrl, {
            description: description,
            category_1: category_1,
            category_2: category_2,
            category_3: category_3,
            amount: amount,
            useful_life: useful_life,
            credit_provider: credit_provider,
            interest_rate: interest_rate,
            compounded: compounded,
            payment_plan: payment_plan,
            payment_amount: payment_amount,
            loan_term: loan_term,
        })
    
        return response.data;
    }catch(error){
        return error.response;
    }
    
}

export const createLiabilityEntry = async(
    description, category_1, category_2, amount,
    creditor, payment_plan, payment_amount, interest_rate, compounded, loan_term, liabilityBaseUrl) => {
    try{
        const response = await axios.post(liabilityBaseUrl, {
            description: description,
            category_1: category_1,
            category_2: category_2,
            amount: amount,
            creditor: creditor,
            payment_plan: payment_plan,
            payment_amount: payment_amount,
            interest_rate: interest_rate,
            compounded: compounded,
            loan_term: loan_term
        })
        return response.data
    }catch(error){
        return error.response;
    }
}


export const createAssetHybridEntry = async(
    description, category_1, category_2, category_3, amount, 
    useful_life, credit_provider, interest_rate, compounded, payment_plan,
    payment_amount, loan_term, amount_credit, amount_cash, assetHybridBaseUrl) => {
        try{
            const response = await axios.post(assetHybridBaseUrl,{
                description: description,
                category_1: category_1,
                category_2: category_2,
                category_3: category_3,
                amount: amount,
                useful_life: useful_life,
                credit_provider: credit_provider,
                interest_rate: interest_rate,
                compounded: compounded,
                payment_plan: payment_plan,
                payment_amount: payment_amount,
                loan_term: loan_term,
                amount_credit: amount_credit,
                amount_cash: amount_cash
            })
        return response.data;
        }catch(error){
            return error.response
        }
        
}

export const createPayablesExpenseEntry = async(
    description, category_1, category_2, category_3, amount,
    name_and_surname_or_company_name, address_1, address_2, user, city, province, postal_code,
    email, cell_number_or_telephone_number, repayment_date, expensePayableBaseUrl
) => {
    try{
        const response = await axios.post(expensePayableBaseUrl, {
            description: description,
            category_1: category_1,
            category_2: category_2,
            category_3: category_3,
            amount: amount,
            name_and_surname_or_company_name: name_and_surname_or_company_name,
            email: email,
            address_1: address_1,
            address_2: address_2,
            city: city,
            province: province,
            cell_number_or_telephone_number: cell_number_or_telephone_number,
            postal_code: postal_code,
            user: user,
            repayment_date: repayment_date})
        return response.data;
    }catch(error){
        return error.response;
    }
    
}

export const createReceivablesIncomeEntry = async(
    description, category_1,
    category_2, category_3,
    amount, name_and_surname_or_company_name, address_1,
    user, address_2, city, province,postal_code,
    email, cell_number_or_telephone_number, repayment_date, incomeReceivableBaseUrl) => {

    try{
        const response = await axios.post(incomeReceivableBaseUrl, {
            description: description,
            category_1: category_1,
            category_2: category_2,
            category_3: category_3,
            amount: amount,
            name_and_surname_or_company_name: name_and_surname_or_company_name,
            email: email,
            address_1: address_1,
            address_2: address_2,
            city: city,
            province: province,
            cell_number_or_telephone_number: cell_number_or_telephone_number,
            postal_code: postal_code,
            user: user,
            repayment_date: repayment_date})
        return response.data;
    }catch(error){
        return error.response;
    }

}

const authService = new AuthService();

export default authService;