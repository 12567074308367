import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TablePagination from "@material-ui/core/TablePagination";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {Card, CardHeader, Divider } from "@material-ui/core";


const columns = [
  { id: "date", label: "Date", minWidth: 70 },
  { id: "description", label: "Description", minWidth: 140 },
  {
    id: "amount",
    label: "Amount",
    minWidth: 70,
    align: "right",
    format: value => value.toLocaleString("en-US"),
  },
];


const useStyles = makeStyles({
  root: {
    width: "100%",
    
  },
  container: {
    maxHeight: 440,
  },
  cardRoot: {
    width: "50%",
    height: "100%",
    marginLeft: 23,
  },
});

function PayablesTable(props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const payableTable = props.dashboard.data?.payables_list
  console.log(payableTable);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Card className={classes.cardRoot}>
      <CardHeader style={{color: "#5c5b5f"}} title="Payables" />
      <Divider />
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>

              { payableTable ?
                payableTable
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(row => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.code}
                      >
                        {columns.map(column => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format && typeof value === "number"
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })
              : ""}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
        rowsPerPageOptions={[5, 8]}
        component="div"
        count={payableTable ? payableTable.length : ""}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      </Paper>
    </Card>
  );
}

export default PayablesTable;
