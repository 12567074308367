import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    totalCardRoot:{
        width:  '25%',
        height: '490px',
        marginRight: 20,
        marginLeft: 20,
        textAlign: "center"
    },

    cardTitleFonts:{
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontWeight: 520,
        marginTop: 50,
        fontSize: '16px',
        color: "rgb(107, 119, 140)",
        marginBottom: "10px"
    },

    cardAmountFonts:{
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontWeight: 520,
        fontSize: '24px',
        marginBottom: "20px"
    },    
    image: {
        width: '20%'
    },
    amount:{
        color:"rgb(57, 73, 171)",
        textAlign: 'center',
        fontSize: '25px',
        fontWeight: 520,
    }

}));

export default useStyles;