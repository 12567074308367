import { makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    text:{
        display: 'grid',
        placeItems: 'center'
    },
    image:{
        marginBottom: 34
    },
}))

export default useStyles