import { configureStore} from "@reduxjs/toolkit";
import {combineReducers} from "redux"
import {persistStore, persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import userReducer from "../redux/useSlice";
import companyDBSlice from "./companyDbSlice";
import thunk from 'redux-thunk'

const reducers  = combineReducers({
  user: userReducer,
  companyDB: companyDBSlice,
});

const persistConfig = {
  key: "root",
  storage
}
const persistedReducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export const persistedStore = persistStore(store)
