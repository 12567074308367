import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    root:{
        width: "90%",
        minHeight: "530px",
        backgroundColor: "white",
        borderRadius: "5px",
        margin: "auto",
    },

    table: {
        minWidth: 650,
    },

    historyNote:{
        fontFamily: "Segoe UI, Tahoma, Geneva, Verdana, sans-serif",
        fontWeight: 510,
        fontSize:" larger"
    }
}))


export default useStyles;