import { Badge, Button, Card, CardContent, Typography } from '@material-ui/core'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import GlobalStyles from "../../styles/GlobalStyles";

import './PrimaryCard.css'

const useStyles = makeStyles({
  Buttonroot: {
    float: 'right',
    marginRight: '5px',
    marginBottom: '10px',
  },
  cardDescription: {
    textAlign: 'center'
  }
});


function PrimaryCard({cardName, cardPic, cardDescription, button, cardAvailability, color}) {
    const classes = useStyles();
    const globalClasses = GlobalStyles();
    return (
      <Card className="operation__cards">
        <Badge
          color={color} 
          overlap="circular"
          badgeContent={cardAvailability}
        >
          <CardContent className="card__info">
            <img className="card__pic" src= {cardPic} />
            <Typography
            color="initial"
            variant="body1"
            className={`${globalClasses.ibs_mb_10} ${globalClasses.ibs_font_weight_md}`}
            style={{color: "#5c5b5f"}}
            >
              {cardName}
            </Typography>
            <small style={{color: "#5c5b5f"}} className={classes.cardDescription}>{cardDescription}</small>
          </CardContent>
        </Badge>
      </Card>        
    )
}

export default PrimaryCard
