import { createStyles, makeStyles } from '@material-ui/core';
import { createTheme } from '@mui/material/styles';

export const IBS_THEME = createTheme({
  typography: {
    fontFamily: [
      "Encode Sans Semi Expanded",
      "sans-serif"
    ].join(",")
  },
  palette: {
    primary: {
      main: '#773ab3b6',
    },
    secondary: {
      main: '#AF71EC'
    },
    error: {
      main: "#e12534"
    }
  }
});

const useStyles = makeStyles(() => createStyles({
  ibs_p_20:{
    padding: "20px"
  },
  ibs_pl_20:{
    paddingLeft: "20px"
  },
  ibs_pr_20:{
    paddingRight: "20px"
  },
  ibs_ptl_20:{
    paddingBlock: "20px"
  },
  ibs_flex_items:{
    display: "flex",
  },
  ibs_typography_color:{
    color: "#5c5b5f" 
  },
  ibs_flex_alignment: {
    display: "flex",
    alignItems: "center"
  },
  ibs_column_alignment:{
    display: "flex",
    flexDirection: "column",
  },
  ibs_row_alignment:{
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
  },
  ibs_gap_10:{
    gap: "10px"
  },
  ibs_grid_center: {
    display: "grid",
    placeItems: "center"
  },
  ibs_typography_header: {
    fontSize: "30px",
    color: '#5c5b5f',
    fontWeight: "600",
  },
  ibs_typography_subtitle: {
    fontSize: "19px",
    color: '#AF71EC',
    fontWeight: "500"
  },
  ibs_typography_small:{
    fontSize: "14px",
    color: '#5c5b5f'
  },
  ibs_flex_height: {
    display: "flex",
    height: "100vh",
  },
  ibs_container_spacer: {
    flex: "1"
  },
  ibs_font_weight_sm: {
    fontWeight: "500"
  },
  ibs_font_size_md: {
    fontSize: "18px"
  },
  ibs_font_size_sm:{
    fontSize: "14px"
  },
  ibs_font_weight_md: {
    fontWeight: "600"
  },
  ibs_mt_90:{
    marginTop: "90px",
  },
  ibs_mb_20: {
    marginBottom: "20px"
  },
  ibs_mb_30: {
    marginBottom: "30px"
  },
  ibs_mr_20: {
    marginRight: "20px"
  },
  ibs_ml_20: {
    marginLeft: "20px !important"
  },
  ibs_grid_columns_3: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 190px)",
    gridGap: "10px"
  },
  ibs_grid_columns_4: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 190px)",
    gridGap: "10px"
  },
  ibs_alignment_end: {
    alignSelf: "flex-end"
  },
  ibs_mt_20: {
    marginTop: "20px"
  },
  ibs_max_card: {
    width: "200px",
    height: "200px"
  },
  ibs_link_unset: {
    textDecoration: "none"
  },
  // ibs_mb_30: {
  //   marginBottom: "30px"
  // },
  ibs_ml_30: {
    marginLeft: "30px"
  },
  ibs_margin_unset: {
    margin: "0"
  },
  ibs_mb_10: {
    marginBottom: "10px"
  },
  ibs_mb_4: {
    marginBottom: "4px"
  },
  ibs_text_color: {
    color: "#5c5b5f"
  },
  ibs_loading_screen:{
    backgroundImage: 'linear-gradient(to bottom,#654a86,#534292)',
    backgroundColor: "#534292"
  },
  ibs_vertically_center_container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: '100vh'
  },
  ibs_center_flex_items:{
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  // ibs_margin_unset:{
  //   margin: 0
  // },
  ibs_mt_auto: {
    marginTop: "auto"
  }
}));

const GlobalStyles = () => {
  return useStyles();
};

export default GlobalStyles;
