import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 40,
  },
  companyDetails: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  companyName: {
    fontSize: 19,
    marginBottom: 10
  },
  title: {
    fontSize: 24,
    marginBottom: 10,
    color: '#AF71EC',
  },
  section: {
    marginBottom: 20,
  },
  date:{
    fontSize: 12,
    marginBottom: 10
  },
  boldText: {
    fontWeight: 'bold',
    backgroundColor: '#AF71EC',
    color: '#fff'
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: 1,
    borderBottomColor: '#ccc',
    paddingBottom: 8,
    paddingTop: 8,
  },
  label: {
    width: '70%',
    color: '#555',
    fontSize: 16
  },
  value: {
    width: '30%',
    textAlign: 'right',
  },
});

const IncomeStatementTemplate = ({ incomeData }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.companyDetails}>
        <Text style={styles.title}>Income Statement</Text>
        <Text style={styles.companyName}>Company Name: {incomeData.company_name}</Text>
      </View>

      <View>
        <Text style={styles.date}>For the Years Ending [{incomeData.start_date} and {incomeData.end_date}]</Text>
        <Text style={styles.date}>Registration Number: {incomeData.reg_number}</Text>
      </View>

      {/* Revenue section */}
      <View style={styles.section}>
        <Text style={styles.boldText}>Revenue</Text>
        {incomeData.income_data ? incomeData.income_data.map((income) => (
          <View style={styles.row}>
            <Text style={styles.label}>{income.income_stream}</Text>
            <Text style={styles.value}>R {income.amount}</Text>
          </View>
        ))
          :
          null
        }
        
        <View style={styles.section}>
          <View style={styles.row}>
            <Text style={styles.boldText}>Total Income</Text>
            <Text style={styles.value}>R {incomeData.income_total}</Text>
          </View>
        </View>
      </View>

      {/* Expenses section */}
      <View style={styles.section}>
        <Text style={styles.boldText}>Expenses</Text>
        {incomeData.expense_data ? incomeData.expense_data.map((expense) => (
          <View style={styles.row}>
            <Text style={styles.label}>{expense.expense_stream}</Text>
            <Text style={styles.value}>R {expense.amount}</Text>
        </View>
       
        ))
        : 
        null
      }
        <View style={styles.section}>
          <View style={styles.row}>
            <Text style={styles.boldText}>Total Expense</Text>
            <Text style={styles.value}>R {incomeData.expense_total}</Text>
          </View>
        </View>
      </View>

      {/* Net Income */}
      <View style={styles.section}>
        <View style={styles.row}>
          <Text style={styles.boldText}>Net Profit</Text>
          <Text style={styles.value}>R {incomeData.net_profit}</Text>
        </View>
      </View>
    </Page>
  </Document>
);

export default IncomeStatementTemplate;
