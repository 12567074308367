import React from "react";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import GlobalStyles from "../styles/GlobalStyles";

function FlashMessage({ message, severity }) {
    const globalClasses = GlobalStyles();
  return (
    <Stack className={globalClasses.ibs_grid_center} sx={{ width: '100%', textAlign: 'center' }}>
      <Alert variant="filled" style={{maxWidth: '400px'}}severity={severity}>{message}</Alert>
    </Stack>
  );
}

export default FlashMessage;
